exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n}\n\n._3lDML {\n  border-bottom: 1px solid rgba(0, 0, 0, .15);\n  position: relative;\n  font-size: 16px\n}\n\n._3lDML::before {\n  bottom: 0;\n  content: '';\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  opacity: 0.075;\n}\n\n._3lDML:nth-child(even)::before {\n  background-color: #000000;\n}\n\n.PNyr- {\n  display: block;\n  color: #9b9b9b;\n  font-size: 16px;\n}\n\n.HwdFK {\n  display: block;\n  font-size: 20px;\n  text-align: center;\n  padding: 32px;\n}\n", ""]);

// exports
exports.locals = {
	"theater": "_3lDML",
	"text": "PNyr-",
	"noResults": "HwdFK"
};