exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2DIAV {\n  width: 10px;\n  height: 20px;\n}\n\n._2DIAV._2Io-_ {\n  height: 8px;\n}\n\n._2DIAV._2owRl polyline {\n  stroke: currentColor;\n}\n\n._2DIAV._2Io-_ polyline {\n  stroke: currentColor;\n  stroke-width: 12;\n}\n", ""]);

// exports
exports.locals = {
	"arrow": "_2DIAV",
	"white": "_2Io-_",
	"black": "_2owRl"
};