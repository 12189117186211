// eslint-disable-next-line import/prefer-default-export
export const ELEMENT_KEYS = {
  MAIN: 'main',
  MAP_MARKER: 'map.marker',
  SEARCH_INPUT: 'search.input',
  SEARCH_LOCATION: 'search.location',
  SEARCH_BUTTON: 'search.button',
  SEARCH_BUTTON_HOVER: 'search.button:hover',
  DATES_CONTROLS: 'dates.controls',
  DATES_CONTROLS_HOVER: 'dates.controls:hover',
  DATES_ITEM: 'dates.item',
  DATES_ITEM_SELECTED: 'dates.item:selected',
  THEATERS: 'theaters',
  THEATERS_LOADER: 'theaters.loader',
  THEATERS_ITEM: 'theaters.item',
  THEATERS_ITEM_BADGE: 'theaters.item.badge',
  THEATERS_PAGINATION_BUTTON: 'theaters.pagination.button',
  THEATERS_PAGINATION_BUTTON_HOVER: 'theaters.pagination.button:hover',
  SCHEDULES_BUTTON: 'schedules.button',
  SCHEDULES_BUTTON_HOVER: 'schedules.button:hover',
  SCHEDULES_FORMAT: 'schedules.format',
};
