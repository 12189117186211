import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import SvgArrowLeft from 'components/icons/ArrowLeft';
import SvgArrowRight from 'components/icons/ArrowRight';
import SvgCalendar from 'components/icons/Calendar';
import geolocShape from 'shared/shapes/geoloc';
import { ELEMENT_KEYS } from 'shared/constants';

import { toDate } from 'utils/date';

import css from './styles.css';

import Day from './Day';
import Scroller from './Scroller';

let onRightFn;
let onLeftFn;

class Calendar extends Component {
  state = {
    calendarControlHover: false,
    fixed: false,
    nextControlHover: false,
    prevControlHover: false,
  };

  render() {
    const { getElementStyle } = this.context;
    const {
      appLocale,
      date,
      onOpenDatePicker,
      onDate,
      reset,
      isReseted,
      countries,
      movieId,
      search,
      radius,
      location,
    } = this.props;
    const {
      fixed,
      prevControlHover,
      nextControlHover,
      calendarControlHover,
    } = this.state;

    const controlStyles = getElementStyle(ELEMENT_KEYS.DATES_CONTROLS);
    const controlHoveredStyles = {
      ...controlStyles,
      ...getElementStyle(ELEMENT_KEYS.DATES_CONTROLS_HOVER),
    };

    return (
      <div
        ref={calendar => (this.calendarPositionElement = calendar)}
        className={css.container}
      >
        <div
          ref={calendar => (this.calendarElement = calendar)}
          className={classnames(css.calendar, { [css.fixed]: fixed })}
        >
          <div
            onClick={() => onLeftFn()}
            className={classnames(css.arrowLeft, css.center)}
            style={prevControlHover ? controlHoveredStyles : controlStyles}
            onMouseEnter={() => this.setState({ prevControlHover: true })}
            onMouseLeave={() => this.setState({ prevControlHover: false })}
          >
            <SvgArrowLeft />
          </div>
          <div className={css.days}>
            <Scroller
              goNext={onRight => (onRightFn = onRight)}
              goPrev={onLeft => (onLeftFn = onLeft)}
              selected={date}
              reset={reset}
              isReseted={isReseted}
              render={(dayDate, disabled) => (
                <Day
                  appLocale={appLocale}
                  key={dayDate}
                  date={dayDate}
                  selected={toDate(date) === toDate(dayDate)}
                  disabled={disabled}
                  onClick={onDate}
                />
              )}
              countries={countries}
              movieId={movieId}
              search={search}
              radius={radius}
              location={location}
            />
          </div>
          <div
            onClick={() => onRightFn()}
            className={classnames(css.arrowRight, css.center)}
            style={nextControlHover ? controlHoveredStyles : controlStyles}
            onMouseEnter={() => this.setState({ nextControlHover: true })}
            onMouseLeave={() => this.setState({ nextControlHover: false })}
          >
            <SvgArrowRight />
          </div>
          <div
            onClick={onOpenDatePicker}
            className={classnames(css.buttonCalendar, css.center)}
            style={calendarControlHover ? controlHoveredStyles : controlStyles}
            onMouseEnter={() => this.setState({ calendarControlHover: true })}
            onMouseLeave={() => this.setState({ calendarControlHover: false })}
          >
            <SvgCalendar />
          </div>
        </div>
      </div>
    );
  }
}

Calendar.contextTypes = {
  getElementStyle: PropTypes.func.isRequired,
};

Calendar.propTypes = {
  appLocale: PropTypes.string.isRequired,
  onDate: PropTypes.func.isRequired,
  onOpenDatePicker: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  isReseted: PropTypes.func,
  reset: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.string),
  movieId: PropTypes.string.isRequired,
  location: geolocShape,
  radius: PropTypes.number,
  search: PropTypes.string,
};

export default Calendar;
