import { defineMessages } from 'react-intl';

export default defineMessages({
  search: {
    id: 'search-bar-button-message',
    defaultMessage: 'Search',
  },
  placeholder: {
    id: 'search-bar-placeholder-message',
    defaultMessage: 'Search by theater name or city',
  },
  city: {
    id: 'search-bar-city-message',
    defaultMessage: 'City',
  },
  theaters: {
    id: 'search-bar-theaters-message',
    defaultMessage: 'Theaters',
  },
  aroundMe: {
    id: 'search-bar-around-me-message',
    defaultMessage: 'Around Me',
  },
  closeResults: {
    id: 'search-bar-close-results-message',
    defaultMessage: 'Close results',
  },
  noResults: {
    id: 'search-bar-close-no-results-message',
    defaultMessage: 'Sorry no results for your search...',
  },
});
