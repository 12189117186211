import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import ApolloQuery from 'utils/apollo/ApolloQuery';

const query = gql`
  query getMovie(
    $countries: [CountryCode]
    $from: DateTime
    $id: String
    $location: CoordinateType
    $radius: Float
    $search: String
    $to: DateTime
  ) {
    movie(id: $id) {
      id
      title
      languages
      showtimesDates(
        country: $countries
        from: $from
        location: $location
        radius: $radius
        search: $search
        to: $to
      )
    }
  }
`;

const GetMovie = ({
  children,
  countries,
  dateRange,
  id,
  location,
  radius,
  search,
  skip,
}) => (
  <ApolloQuery
    query={query}
    parser={({ movie }) => movie}
    variables={{
      countries,
      from: dateRange ? dateRange.from : undefined,
      id,
      location,
      radius,
      search,
      to: dateRange ? dateRange.to : undefined,
    }}
    skip={skip}
  >
    {children}
  </ApolloQuery>
);

GetMovie.propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default GetMovie;
