exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1jNZU {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  height: 40px;\n}\n\n._2uxGY {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n._3ngAC {\n  -webkit-box-flex: 1;\n      -ms-flex: 1 1;\n          flex: 1 1;\n  cursor: pointer;\n}\n\n._1yf5t {\n  -webkit-box-flex: 6;\n      -ms-flex: 6 1;\n          flex: 6 1;\n  font-weight: 700;\n  font-size: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_1jNZU",
	"center": "_2uxGY",
	"button": "_3ngAC",
	"title": "_1yf5t"
};