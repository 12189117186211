exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n}\n\n._2fXb8 {\n  width: 14%;\n  height: 40px;\n  background-color: #f4f4f4;\n  color: #9b9b9b;\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  cursor: pointer;\n  -webkit-transition: background-color 200ms ease-in-out, border 200ms ease-in-out;\n  -o-transition: background-color 200ms ease-in-out, border 200ms ease-in-out;\n  transition: background-color 200ms ease-in-out, border 200ms ease-in-out;\n}\n\n._31-YT {\n  background-color: #ffffff;\n  color: #333333;\n}\n\n.g-_r0 {\n  font-weight: bolder;\n  background-color: #b0dbfb;\n  border: 1px solid #2089ec;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_2fXb8",
	"active": "_31-YT",
	"selected": "g-_r0"
};