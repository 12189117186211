import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import geolocShape from 'shared/shapes/geoloc';
import { GOOGLE_MAPS_CALLBACK } from 'config/map';
import cancelable from 'makecancelable';

import { gmapScriptUrl } from 'utils/map';
import ScriptLoader from 'shared/components/ScriptLoader';
import ApolloQueryPromise, {
  CLIENT_CONTEXT_TYPE,
} from 'utils/apollo/ApolloQueryPromise';

import { query, parser } from './queries/GetTheaters';

import Gmap from './Component';
import css from './styles.css';

import tracking, { EventType } from '../../utils/tracking';

const THEATERS_COUNT = 99; // not 100 to prevent actual graphQl api bug
const DEBOUNCER_TIMEOUT = 1000;

class MapWrapper extends PureComponent {
  state = {
    theaters: [],
    pageInfo: undefined,
  };

  componentWillMount() {
    global[GOOGLE_MAPS_CALLBACK] = () => this.setState({ loaded: true });
  }

  componentDidMount() {
    this.getTheatersDebounced();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.movieId !== this.props.movieId ||
      prevProps.countries !== this.props.countries ||
      prevProps.dateRange !== this.props.dateRange ||
      prevProps.location !== this.props.location ||
      prevProps.maxTheaters !== this.props.maxTheaters ||
      prevProps.search !== this.props.search
    ) {
      this.getTheatersDebounced();
    }
  }

  getTheatersDebounced = () => {
    clearTimeout(this.deboucedTheaters);
    this.deboucedTheaters = setTimeout(() => {
      this.setState(
        {
          pageInfo: undefined,
        },
        () => this.getTheatersCancelable(),
      );
    }, DEBOUNCER_TIMEOUT);
  };

  getTheaters = async () => {
    const { pageInfo } = this.state;
    if (pageInfo && !pageInfo.hasNextPage) return false;

    const {
      countries,
      dateRange: { from, to },
      location,
      maxTheaters,
      movieId,
      radius,
      search,
    } = this.props;

    const theaters = await ApolloQueryPromise(
      this.context.client,
      query,
      {
        after: pageInfo && pageInfo.endCursor ? pageInfo.endCursor : undefined,
        countries,
        endDate: to,
        first: THEATERS_COUNT,
        location: { lat: location.lat, lon: location.lng },
        maxTheaters,
        movieId,
        order: ['CLOSEST', 'ALPHABETICAL'],
        radius,
        search,
        startDate: from,
      },
      { fetchPolicy: 'no-cache' },
    );
    const parsedTheaters = parser(theaters) || [];
    const allTheaters = pageInfo ? this.state.theaters : [];
    tracking(EventType.LIST_RESULTS, {
      search,
      date: from.split(' ')[0],
      results: parsedTheaters.length,
    });

    return {
      pageInfo: theaters.theaterShowtimeList.pageInfo,
      theaters: [...allTheaters, ...parsedTheaters],
    };
  };

  getMarkersFromTheaters = theaters =>
    !theaters
      ? theaters
      : theaters.map(theater => ({
        id: theater.id,
        coords: {
          lat: parseFloat(theater.coordinates.latitude),
          lng: parseFloat(theater.coordinates.longitude),
        },
      }));

  getTheatersCancelable = () => {
    if (this.cancelGetTheaters) this.cancelGetTheaters();

    this.cancelGetTheaters = cancelable(this.getTheaters(), result => {
      if (!result) return;
      this.setState(result, () => this.getTheatersCancelable());
    });
  };

  render() {
    const {
      location = {},
      selectedTheaterId,
      googleApiKey,
      mapOptions,
    } = this.props;

    const { theaters } = this.state;
    const markers = this.getMarkersFromTheaters(theaters);

    const selectedMarker =
      selectedTheaterId && markers && markers.length > 0
        ? markers.find(marker => marker.id === selectedTheaterId)
        : markers[0];

    const resolvedLocation = selectedMarker ? selectedMarker.coords : location;

    return (
      <div className={css.container}>
        <ScriptLoader
          url={gmapScriptUrl(googleApiKey)}
          canLoad={!global.google || (!global.google && !global.google.map)}
        >
          {loaded => {
            return (
              <Gmap
                isMapScriptLoaded={loaded}
                location={resolvedLocation}
                markers={markers}
                mapOptions={mapOptions}
              />
            );
          }}
        </ScriptLoader>
      </div>
    );
  }
}

MapWrapper.contextTypes = {
  client: CLIENT_CONTEXT_TYPE,
};

MapWrapper.defaultProps = {
  selectedTheaterId: undefined,
  mapOptions: undefined,
};

MapWrapper.propTypes = {
  movieId: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
  location: geolocShape,
  dateRange: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  maxTheaters: PropTypes.number,
  search: PropTypes.string,
  selectedTheaterId: PropTypes.string,
  mapOptions: PropTypes.object,
};

export default MapWrapper;
