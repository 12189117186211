exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n}\n\n._1umBY {\n  margin: 10px 0;\n  height: 27px;\n  width: 27px;\n  -webkit-animation: _27Esa 1s infinite linear;\n          animation: _27Esa 1s infinite linear;\n  border: 4px solid currentColor;\n  border-right-color: transparent;\n  border-radius: 50%;\n  display: inline-block;\n}\n\n@-webkit-keyframes _27Esa {\n  0% {\n    -webkit-transform: rotate(0deg);\n            transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n            transform: rotate(360deg);\n  }\n}\n\n@keyframes _27Esa {\n  0% {\n    -webkit-transform: rotate(0deg);\n            transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n            transform: rotate(360deg);\n  }\n}\n", ""]);

// exports
exports.locals = {
	"spinner": "_1umBY",
	"rotate": "_27Esa"
};