import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape } from 'react-intl';

import SvgArrowLeft from 'components/icons/ArrowLeft';
import SvgArrowRight from 'components/icons/ArrowRight';
import { THEMES } from 'components/icons/constants';

import Button from './Button';
import css from './styles.css';

const messages = defineMessages({
  previous: {
    id: 'paging-previous-message',
    defaultMessage: 'Prev',
  },
  next: {
    id: 'paging-next-message',
    defaultMessage: 'Next',
  },
});

export const DIRECTIONS = {
  PREV: 'prev',
  NEXT: 'next',
};

const Paging = ({ onClick, disabled, hasPrevious, hasNext }, { intl }) => (
  <div className={css.paging}>
    {hasPrevious && (
      <Button
        className={css.prev}
        onClick={() => onClick(DIRECTIONS.PREV)}
        disabled={disabled}
      >
        <SvgArrowLeft className={css.icon} theme={THEMES.WHITE} />
        {intl.formatMessage(messages.previous)}
      </Button>
    )}
    {hasNext && (
      <Button
        className={css.next}
        onClick={() => onClick(DIRECTIONS.NEXT)}
        disabled={disabled}
      >
        {intl.formatMessage(messages.next)}
        <SvgArrowRight className={css.icon} theme={THEMES.WHITE} />
      </Button>
    )}
  </div>
);

Paging.contextTypes = {
  intl: intlShape.isRequired,
};

Paging.default = {
  disabled: false,
  hasPrevious: false,
  hasNext: false,
};

Paging.propTypes = {
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Paging;
