import values from 'lodash/values';

export const KEY = {
  UP: 38,
  DOWN: 40,
  ESC: 27,
  ENTER: 13,
  SPACE: 32,
  J: 74,
  K: 75,
};

export const KEYS = values(KEY);
