import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { DEFAULT_API_ENDPOINT } from '../../../public/constants';

const Provider = ({ config, children }) => {
  const cache = new InMemoryCache();
  const token = config.token;

  const httpLink = new HttpLink({
    uri: process.env.API_ENDPOINT || DEFAULT_API_ENDPOINT,
  });
  const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return forward(operation);
  });

  const link = middlewareLink.concat(httpLink);
  const client = new ApolloClient({ link, cache });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Provider;
