import { PureComponent } from 'react';

import tracking, { EventType } from 'utils/tracking';

const STORAGE_NAME = 'position';

const GEOLOC_NOT_EXIST = !navigator || !navigator.geolocation;

class GetPosition extends PureComponent {
  constructor(props) {
    super(props);

    const position = this.getDefaultPosition(
      props.saveUserGeoloc,
      props.defaultPosition,
    );

    this.state = { position, promptIsOpen: false };
  }

  getDefaultPosition(saveUserGeoloc, defaultPosition) {
    let position = defaultPosition;
    if (saveUserGeoloc && window && window.localStorage) {
      const storedPosition = window.localStorage.getItem(STORAGE_NAME);
      if (storedPosition) position = JSON.parse(storedPosition);
    }
    return position;
  }

  getCurrentPosition = saveUserGeoloc =>
    new Promise((resolve, reject) => {
      if (GEOLOC_NOT_EXIST) return reject();
      tracking(
        EventType.GEOLOCATION_REQUEST, {}
      );
      return navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          const position = { lat: coords.latitude, lng: coords.longitude };
          this.setState({ position });
          if (saveUserGeoloc && window && window.localStorage) {
            window.localStorage.setItem('position', JSON.stringify(position));
          }
          tracking(
            EventType.GEOLOCATION_ACQUIRED,
            {
              position
            }
          );
          resolve(position);
        },
        () => resolve(undefined),
      );
    });

  openPrompt = () =>
    new Promise((resolve, reject) => {
      if (GEOLOC_NOT_EXIST) return reject();
      const { saveUserGeoloc } = this.props;

      this.setState({ promptIsOpen: true });
      this.getCurrentPosition(saveUserGeoloc).then(() =>
        this.setState({ promptIsOpen: false }),
      );

      return true;
    });

  render() {
    const { children } = this.props;
    const { position, promptIsOpen } = this.state;
    return children(position || undefined, {
      promptIsOpen,
      openPrompt: this.openPrompt,
    });
  }
}

GetPosition.defaultProps = {
  saveUserGeoloc: false,
  defaultPosition: false,
};

export default GetPosition;
