import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { defineMessages, intlShape } from 'react-intl';

import css from './style.css';

const messages = defineMessages({
  today: {
    id: 'datepicker-today-footer-button-label',
    defaultMessage: 'Today',
  },
  close: {
    id: 'datepicker-close-footer-button-label',
    defaultMessage: 'Close',
  },
});

const Footer = ({ onToday, onClose }, { intl }) => (
  <div className={classnames(css.container, css.sections)}>
    <div
      className={classnames(css.section, css.action)}
      onClick={() => onToday(new Date())}
    >
      {intl.formatMessage(messages.today)}
    </div>

    <div className={classnames(css.section, css.action)} onClick={onClose}>
      {intl.formatMessage(messages.close)}
    </div>
  </div>
);

Footer.contextTypes = {
  intl: intlShape.isRequired,
};

Footer.propTypes = {
  onToday: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Footer;
