import React from 'react';

import css from './styles.css';

const LocateIcon = () => (
  <svg
    className={css.icon}
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
  >
    <path d="M61.11,25.425H38.89c-0.83,0-1.5,0.67-1.5,1.5v14.92c0,0.82,0.67,1.5,1.5,1.5h22.22c0.83,0,1.5-0.68,1.5-1.5v-14.92  C62.61,26.095,61.94,25.425,61.11,25.425z M43.7,40.354c0,0.28-0.22,0.5-0.5,0.5h-2.66c-0.28,0-0.5-0.22-0.5-0.5v-2.67  c0-0.27,0.22-0.5,0.5-0.5h2.66c0.28,0,0.5,0.23,0.5,0.5V40.354z M43.7,35.595c0,0.28-0.22,0.5-0.5,0.5h-2.66  c-0.28,0-0.5-0.22-0.5-0.5v-2.66c0-0.279,0.22-0.5,0.5-0.5h2.66c0.28,0,0.5,0.221,0.5,0.5V35.595z M43.7,30.845  c0,0.271-0.22,0.5-0.5,0.5h-2.66c-0.28,0-0.5-0.229-0.5-0.5v-2.67c0-0.28,0.22-0.5,0.5-0.5h2.66c0.28,0,0.5,0.22,0.5,0.5V30.845z   M52.26,35.015l-4.02,2.32c-0.08,0.04-0.16,0.07-0.25,0.07c-0.08,0-0.17-0.03-0.25-0.07c-0.15-0.09-0.25-0.26-0.25-0.43v-4.641  c0-0.18,0.1-0.35,0.25-0.43c0.16-0.09,0.35-0.09,0.5,0l4.02,2.31c0.15,0.091,0.25,0.261,0.25,0.44  C52.51,34.755,52.41,34.925,52.26,35.015z M59.96,40.354c0,0.28-0.22,0.5-0.5,0.5H56.8c-0.28,0-0.5-0.22-0.5-0.5v-2.67  c0-0.27,0.22-0.5,0.5-0.5h2.66c0.28,0,0.5,0.23,0.5,0.5V40.354z M59.96,35.595c0,0.28-0.22,0.5-0.5,0.5H56.8  c-0.28,0-0.5-0.22-0.5-0.5v-2.66c0-0.279,0.22-0.5,0.5-0.5h2.66c0.28,0,0.5,0.221,0.5,0.5V35.595z M59.96,30.845  c0,0.271-0.22,0.5-0.5,0.5H56.8c-0.28,0-0.5-0.229-0.5-0.5v-2.67c0-0.28,0.22-0.5,0.5-0.5h2.66c0.28,0,0.5,0.22,0.5,0.5V30.845z" />
    <path d="M94.68,91.119L75.84,60.729c-0.36-0.58-1-0.93-1.68-0.93h-4.88c-0.92,1.25-1.91,2.57-2.97,3.949h6.75l5.66,9.131H64.78  c-1.1,0-1.98,0.879-1.98,1.971c0,1.09,0.88,1.979,1.98,1.979h16.39l8.28,13.352H51.97v-4.602c0-0.18-0.02-0.359-0.07-0.52  c-0.19-0.689-0.74-1.221-1.43-1.391c-0.15-0.039-0.31-0.059-0.47-0.059c-1.1,0-1.98,0.879-1.98,1.969v4.602H10.55l8.28-13.352h16.39  c1.09,0,1.97-0.889,1.97-1.979c0-1.092-0.88-1.971-1.97-1.971H21.28l5.65-9.131h6.76c-1.01-1.318-2.01-2.639-2.96-3.949h-4.9  c-0.68,0-1.32,0.35-1.68,0.93L5.32,91.119c-0.38,0.609-0.4,1.369-0.05,2c0.35,0.629,1.01,1.02,1.73,1.02h86  c0.72,0,1.38-0.391,1.73-1.02C95.08,92.488,95.06,91.729,94.68,91.119z" />
    <path d="M78.144,34.004C78.144,18.465,65.54,5.861,50,5.861c-15.549,0-28.144,12.604-28.144,28.143  c0,4.439,1.048,8.611,2.877,12.338h-0.04C28.667,56.178,49.98,80.396,50,80.416c0-0.01,5.338-6.068,11.17-13.355  c5.822-7.285,12.139-15.797,14.126-20.719h-0.039C77.085,42.615,78.144,38.443,78.144,34.004z M50,57.047  c-12.515,0-22.667-10.152-22.667-22.666S37.485,11.723,50,11.723s22.657,10.145,22.657,22.658S62.515,57.047,50,57.047z" />
  </svg>
);

export default LocateIcon;
