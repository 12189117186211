exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n}\n\n._3Bj-k {\n  text-align: center;\n}\n\n._1UYok {\n  width: 14%;\n  height: 40px;\n  color: #9b9b9b;\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  font-weight: bolder;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_3Bj-k",
	"weekDay": "_1UYok"
};