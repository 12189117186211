import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import classnames from 'classnames';

import includes from 'lodash/includes';
import isNumber from 'lodash/isNumber';
import escapeRegExp from 'lodash/escapeRegExp';
import postHeight from 'shared/helpers/postHeight';

import { KEY } from 'utils/keyCodes';
import { find } from 'utils/array';
import tracking, { EventType } from 'utils/tracking';

import LocateIcon from 'components/icons/LocateIcon';
import LoaderCss from 'components/icons/LoaderCss';
import { ELEMENT_KEYS } from 'shared/constants';

import { SEARCH_RESULTS_TYPES } from './constants';
import messages from './intl';

import css from './styles.css';

class SearchBar extends PureComponent {
  state = {
    resultListState: false,
    focusedIndex: null,
    isHovered: false,
  };

  componentWillMount() {
    const { pattern } = this.props;
    if (pattern) {
      this.setState({ resultListState: pattern.length > 2 });
    }
  }

  componentDidUpdate() {
    postHeight(undefined, 300);
  }

  onChange = pattern => {
    const { onChange } = this.props;
    this.setState({ resultListState: pattern.length > 2, focusedIndex: null });
    onChange(pattern);
  };

  onKeyDown = event => {
    const key = event.keyCode;

    if (key === KEY.UP) {
      this.focusItem({ previous: true });
    } else if (key === KEY.DOWN) {
      this.focusItem({ next: true });
    } else if (key === KEY.ENTER) {
      this.selectResult();
    } else if (key === KEY.ESC) {
      this.closeResultList();
    }
    // prevent default behavior, in some situations pressing the key
    // up / down would scroll the browser window
    if (includes([KEY.UP, KEY.DOWN, KEY.ENTER, KEY.ESC], key)) {
      event.preventDefault();
    }
  };

  closeResultList = () => {
    this.onChange('');
    this.setState({ resultListState: false });
  };

  focusItem = ({ next = false, previous = false, index = null } = {}) => {
    let nextFocusedIndex;
    const { searchResults } = this.props;
    const { focusedIndex } = this.state;
    const lastItem = searchResults.length - 1;

    if (next) {
      if (focusedIndex == null) {
        nextFocusedIndex = 0;
      } else {
        // focus first item if reached last item in the list
        nextFocusedIndex = focusedIndex >= lastItem ? 0 : focusedIndex + 1;
      }
    } else if (previous) {
      if (focusedIndex == null) {
        nextFocusedIndex = lastItem;
      } else {
        // focus last item if reached the top of the list
        nextFocusedIndex = focusedIndex <= 0 ? lastItem : focusedIndex - 1;
      }
    } else if (isNumber(index)) {
      nextFocusedIndex = index;
    }

    this.setState({ focusedIndex: nextFocusedIndex, resultListState: true });
  };

  highlightText = text => {
    const { pattern } = this.props;
    const cleanPattern = escapeRegExp(pattern);
    if (pattern.length === 0) return <span>{text}</span>;

    // Use enclosing parentheses, special case in regex split that also returns separator
    const regex = new RegExp(`(${cleanPattern})`, 'gi');
    const parts = text.split(regex);

    return (
      <span>
        {parts.filter(part => part.length > 0).map((part, index) => {
          if (part.match(regex)) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <span className={css.highlighted} key={index}>
                {part}
              </span>
            );
          }
          return <span key={part}>{part}</span>;
        })}
      </span>
    );
  };

  selectResult = () => {
    const { searchResults, onSelect, pattern } = this.props;
    const { focusedIndex } = this.state;

    const selectedResult = find(
      searchResults,
      ({ index }) => index === focusedIndex,
    );

    this.setState(
      {
        resultListState: false,
        focusedIndex: null,
      },
      () => {
        const value = selectedResult ? selectedResult.name : pattern;
        onSelect({
          type: 'search',
          value,
          item: selectedResult,
        });
        tracking(EventType.SEARCH_CHANGE, {
          search: value,
          trigger: selectedResult ? 'autocomplete' : 'free-search',
        });
      },
    );
  };

  clickOnBackground = e => {
    e.preventDefault();
    this.setState({ focusedIndex: 0, resultListState: false });
    this.onChange('');
  };

  renderCategoryList(items, type) {
    const { focusedIndex } = this.state;
    return (
      <ul className={css.categoryList}>
        {items.map(item => (
          <li
            className={classnames(css.item, {
              [css.focused]: item.index === focusedIndex,
            })}
            key={item.id}
            onMouseOver={() => this.focusItem({ index: item.index })}
            onClick={this.selectResult}
          >
            {type === SEARCH_RESULTS_TYPES.CITY
              ? this.highlightText(`${item.name}${item.zip ? ` (${item.zip})` : ''}`)
              : this.highlightText(
                `${item.name} (${item.location.zip ? `${item.location.zip} ` : ''}${item.location.city})`,
              )}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { intl, getElementStyle = () => { } } = this.context;
    const { openGeolocPrompt, pattern, loading, searchResults } = this.props;
    const { resultListState, isHovered } = this.state;

    const styles = getElementStyle(ELEMENT_KEYS.SEARCH_BUTTON);
    const hoveredStyles = {
      ...styles,
      ...getElementStyle(ELEMENT_KEYS.SEARCH_BUTTON_HOVER),
    };

    const cities =
      searchResults &&
      searchResults.filter(result => result.type === SEARCH_RESULTS_TYPES.CITY);
    const theaters =
      searchResults &&
      searchResults.filter(
        result => result.type === SEARCH_RESULTS_TYPES.THEATER,
      );
    const showResults = resultListState && searchResults;

    return (
      <div className={css.searchBar} onKeyDown={this.onKeyDown}>
        <div className={css.content}>
          <input
            className={css.searchInput}
            type="text"
            placeholder={intl.formatMessage(messages.placeholder)}
            onChange={e => this.onChange(e.target.value)}
            value={pattern || ''}
            style={getElementStyle(ELEMENT_KEYS.SEARCH_INPUT)}
          />
          {!loading &&
            !resultListState && (
              <button
                onClick={openGeolocPrompt}
                className={classnames(css.button, css.icon)}
                title={intl.formatMessage(messages.aroundMe)}
                style={getElementStyle(ELEMENT_KEYS.SEARCH_LOCATION)}
              >
                <LocateIcon />
              </button>
            )}
          {loading && (
            <div className={css.icon}>
              <LoaderCss />
            </div>
          )}
          <button
            className={classnames(css.button, css.searchButton)}
            onClick={() => this.selectResult()}
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false })}
            style={isHovered ? hoveredStyles : styles}
          >
            {intl.formatMessage(messages.search)}
          </button>
          {showResults && (
            <div>
              <div
                onClick={this.clickOnBackground}
                className={css.resultListBackground}
              />
              <div onClick={e => e.preventDefault()} className={css.resultList}>
                {resultListState &&
                  !loading &&
                  cities.length === 0 &&
                  theaters.length === 0 && (
                    <div className={css.noResult}>
                      {intl.formatMessage(messages.noResults)}
                    </div>
                  )}
                {cities.length > 0 && (
                  <li className={css.category}>
                    <span className={css.categoryName}>
                      {intl.formatMessage(messages.city)}
                    </span>
                    {this.renderCategoryList(cities, SEARCH_RESULTS_TYPES.CITY)}
                  </li>
                )}
                {theaters.length > 0 && (
                  <li className={css.category}>
                    <span className={css.categoryName}>
                      {intl.formatMessage(messages.theaters)}
                    </span>
                    {this.renderCategoryList(
                      theaters,
                      SEARCH_RESULTS_TYPES.THEATER,
                    )}
                  </li>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

SearchBar.defaultProps = {
  searchResults: [],
};

SearchBar.propTypes = {};

SearchBar.contextTypes = {
  intl: intlShape.isRequired,
  getElementStyle: PropTypes.func,
};

export default SearchBar;
