import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ELEMENT_KEYS } from 'shared/constants';

class Button extends PureComponent {
  state = {
    isHovered: false,
  };

  render() {
    const { onClick, disabled, className, children } = this.props;
    const { getElementStyle } = this.context;
    const { isHovered } = this.state;

    const styles = getElementStyle(ELEMENT_KEYS.THEATERS_PAGINATION_BUTTON);
    const hoveredStyles = {
      ...styles,
      ...getElementStyle(ELEMENT_KEYS.THEATERS_PAGINATION_BUTTON_HOVER),
    };

    return (
      <button
        className={className}
        onClick={onClick}
        disabled={disabled}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() =>
          this.setState({
            isHovered: false,
          })
        }
        style={isHovered ? hoveredStyles : styles}
      >
        {children}
      </button>
    );
  }
}

Button.contextTypes = {
  getElementStyle: PropTypes.func.isRequired,
};

Button.default = {
  className: undefined,
  disabled: false,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default Button;
