import { defineMessages } from 'react-intl';
import { FORMAT_KEYS } from './constants';

export default defineMessages({
  [FORMAT_KEYS.F_2D]: {
    id: 'theater-format-f-2d-message',
    defaultMessage: 'Standard',
  },
  [FORMAT_KEYS.F_3D]: {
    id: 'theater-format-f-3d-message',
    defaultMessage: '3D',
  },
  [FORMAT_KEYS.IMAX]: {
    id: 'theater-format-imax-message',
    defaultMessage: 'IMAX',
  },
  [FORMAT_KEYS.IMAX3D]: {
    id: 'theater-format-imax3d-message',
    defaultMessage: 'IMAX 3D',
  },
});
