import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import ApolloQuery from 'utils/apollo/ApolloQuery';
import { getCurrentCenter } from 'utils/map';

const FIRST_CITIES = 5;

const query = gql`
  query CitySearch(
    $first: Int
    $countries: [CountryCode]
    $location: CoordinateType
    $order: [CitySorting]
    $radius: Float
    $search: String
    $analyzer: Locale
  ) {
    cityList(
      first: $first
      countries: $countries
      location: $location
      order: $order
      radius: $radius
      search: $search
      analyzer: $analyzer
    ) {
      edges {
        node {
          id
          name
          zip
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

const parser = ({ cityList: { edges = [] } = {} } = {}) =>
  edges.map(({ node: { id, name, zip, coordinates } }) => ({
    id,
    name,
    zip,
    coordinates: coordinates
      ? {
          lat: Number(coordinates.latitude),
          lng: Number(coordinates.longitude),
        }
      : null,
  }));

const GetByCity = ({ children, search, analyzer, countries, radius }) => {
  const mapCenter = getCurrentCenter();

  return (
    <ApolloQuery
      query={query}
      parser={parser}
      skip={search.length < 2}
      variables={{
        first: FIRST_CITIES,
        analyzer,
        countries,
        location: mapCenter
          ? { lat: mapCenter.lat(), lon: mapCenter.lng() }
          : undefined,
        order: ['CLOSEST', 'ALPHABETICAL'],
        radius,
        search,
      }}
    >
      {children}
    </ApolloQuery>
  );
};

GetByCity.defaultProps = {
  search: '',
  analyzer: undefined,
  countries: undefined,
};

GetByCity.propTypes = {
  children: PropTypes.func.isRequired,
  search: PropTypes.string,
  analyzer: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.string),
  radius: PropTypes.number.isRequired,
};

export default GetByCity;
