import React from 'react';

import css from './style.css';

const Calendar = () => (
  <svg
    enableBackground="new 0 0 24 24"
    className={css.calendar}
    fill="white"
    version="1.0"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      clipRule="evenodd"
      fillRule="evenodd"
      height="4"
      width="2"
      x="6"
      y="2"
    />
    <rect
      clipRule="evenodd"
      fillRule="evenodd"
      height="4"
      width="2"
      x="16"
      y="2"
    />
    <path d="M19,4H5C3.9,4,3,4.9,3,6v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20H5V9h14V20z" />
    <rect
      clipRule="evenodd"
      fillRule="evenodd"
      height="5"
      width="5"
      x="12"
      y="13"
    />
  </svg>
);

export default Calendar;
