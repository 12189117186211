import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import ApolloQuery from 'utils/apollo/ApolloQuery';
import { addDays } from 'date-fns';
import { toDate } from 'utils/date';
import { getCurrentCenter } from 'utils/map';

const FIRST_THEATERS = 5;

const query = gql`
  query TheaterSearch(
    $countries: [CountryCode]
    $first: Int
    $from: DateTime
    $location: CoordinateType
    $movieId: String!
    $order: [ShowtimeSorting]
    $radius: Float
    $search: String
    $to: DateTime
    $analyzer: Locale
  ) {
    theaterShowtimeList(
      country: $countries
      first: $first
      from: $from
      location: $location
      movie: $movieId
      order: $order
      radius: $radius
      search: $search
      to: $to
      analyzer: $analyzer
    ) {
      edges {
        node {
          theater {
            id
            name
            location {
              city
              zip
            }
            coordinates {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;

const parser = ({ theaterShowtimeList: { edges = [] } = {} } = {}) =>
  edges.map(({ node: { theater: { id, name, coordinates, location } } }) => ({
    id,
    name,
    location,
    coordinates: {
      lat: coordinates && Number(coordinates.latitude),
      lng: coordinates && Number(coordinates.longitude),
    },
  }));

const GetBySearch = ({
  children,
  countries,
  startDate,
  movieId,
  radius,
  search,
  analyzer,
}) => {
  const mapCenter = getCurrentCenter();
  return (
    <ApolloQuery
      query={query}
      parser={parser}
      skip={search.length < 2}
      variables={{
        analyzer,
        countries,
        first: FIRST_THEATERS,
        from: `${toDate(startDate)} 03:00:00`,
        location: mapCenter
          ? { lat: mapCenter.lat(), lon: mapCenter.lng() }
          : undefined,
        movieId,
        order: ['CLOSEST', 'ALPHABETICAL'],
        radius,
        search,
        to: `${toDate(addDays(startDate, 30))} 02:59:59`,
      }}
    >
      {children}
    </ApolloQuery>
  );
};

GetBySearch.defaultProps = {
  search: '',
  analyzer: undefined,
  countries: undefined,
};

GetBySearch.propTypes = {
  children: PropTypes.func.isRequired,
  search: PropTypes.string,
  analyzer: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.instanceOf(Date).isRequired,
  radius: PropTypes.number.isRequired,
};

export default GetBySearch;
