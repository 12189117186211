export const FORMAT_KEYS = {
  F_2D: 'F_2D',
  F_3D: 'F_3D',
  F_4D: 'F_4K',
  F_70MM: 'F_70MM',
  ANALOG: 'ANALOG',
  DIGITAL: 'DIGITAL',
  IMAX: 'IMAX',
  IMAX3D: 'IMAX3D',
  IMAX70MM: 'IMAX70MM',
};

export const GROUP_FROM_FORMATS = {
  // 2d
  [FORMAT_KEYS.ANALOG]: FORMAT_KEYS.F_2D,
  [FORMAT_KEYS.DIGITAL]: FORMAT_KEYS.F_2D,
  [FORMAT_KEYS.F_4K]: FORMAT_KEYS.F_2D,
  [FORMAT_KEYS.F_70MM]: FORMAT_KEYS.F_2D,
  // 3d
  [FORMAT_KEYS.F_3D]: FORMAT_KEYS.F_3D,
  // imax 2d
  [FORMAT_KEYS.IMAX]: FORMAT_KEYS.IMAX,
  [FORMAT_KEYS.IMAX70MM]: FORMAT_KEYS.IMAX,
  // imax 3d
  [FORMAT_KEYS.IMAX3D]: FORMAT_KEYS.IMAX3D,
};

export const FORMAT_PRIORITY = {
  [FORMAT_KEYS.F_2D]: 1,
  [FORMAT_KEYS.F_3D]: 2,
  [FORMAT_KEYS.IMAX]: 3,
  [FORMAT_KEYS.IMAX3D]: 4,
};
