exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n}\n\n._3QFQu {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 999;\n  cursor: pointer;\n  background-color: rgba(0, 0, 0, .4);\n  font-size: 16px;\n  color: #4a4a4a;\n}\n\n._13QGY {\n  background: #ffffff;\n  cursor: auto;\n  cursor: initial;\n  height: 100%;\n  margin: 0;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  width: 100%;\n}\n\n@media (min-width: 768px) {\n\n  ._13QGY {\n    height: auto;\n    margin: 10vh auto;\n    width: 480px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"container": "_3QFQu",
	"content": "_13QGY"
};