import React from 'react';
import PropTypes from 'prop-types';
import { mapTheatersShape } from 'shared/shapes';
import Marker from 'components/Map/Marker';

const Markers = ({ markers, mapInstance }) => (
  <div>
    {markers.map((marker, index) => (
      <Marker
        key={marker.id}
        index={index + 1}
        coords={marker.coords}
        mapInstance={mapInstance}
      />
    ))}
  </div>
);

Markers.propTypes = {
  markers: mapTheatersShape,
  mapInstance: PropTypes.object,
};

export default Markers;
