import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { isSameDay } from 'date-fns';

import { intlYMDString, DATE_FORMATS, toDate } from 'utils/date';

import { inMonth } from '../../helpers';

import css from './style.css';

const Day = ({ appLocale, date, onClick, cursor }) => (
  <div
    onClick={() => onClick(date)}
    className={classnames(css.container, {
      [css.active]: inMonth(cursor, date),
      [css.selected]: isSameDay(cursor, date),
    })}
  >
    {intlYMDString(toDate(date), DATE_FORMATS.DAY, appLocale)}
  </div>
);

Day.propTypes = {
  appLocale: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  cursor: PropTypes.object,
};

export default Day;
