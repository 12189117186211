import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { intlYMDString, DATE_FORMATS, toDate } from 'utils/date';

import { ELEMENT_KEYS } from 'shared/constants';

import css from './style.css';

const Day = (
  { appLocale, disabled, selected, date, onClick },
  { getElementStyle = () => {} },
) => {
  const defaultStyle = getElementStyle(ELEMENT_KEYS.DATES_ITEM);
  const style = selected
    ? { ...defaultStyle, ...getElementStyle(ELEMENT_KEYS.DATES_ITEM_SELECTED) }
    : defaultStyle;

  return (
    <div
      onClick={() => onClick(date)}
      className={classnames(css.day, {
        [css.disabled]: disabled,
        [css.selected]: selected,
      })}
      style={style}
    >
      <div className={css.sections}>
        <div className={css.section}>
          {intlYMDString(toDate(date), DATE_FORMATS.SHORT_MONTH, appLocale)}
        </div>
        <div className={classnames(css.section, css.title)}>
          {intlYMDString(toDate(date), DATE_FORMATS.DAY, appLocale)}
        </div>
        <div className={css.section}>
          {intlYMDString(toDate(date), DATE_FORMATS.WEEKDAY, appLocale)}
        </div>
      </div>
    </div>
  );
};

Day.contextTypes = {
  getElementStyle: PropTypes.func,
};

Day.propTypes = {
  appLocale: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  date: PropTypes.object.isRequired,
};

Day.defaultProps = {
  selected: false,
  disabled: false,
  onClick: () => {},
};

export default Day;
