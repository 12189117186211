export default (eventType, trackingInfos) => {
  if (window && window.Event && window.dispatchEvent) {
    const trackingEvent = new CustomEvent(eventType, {
      detail: trackingInfos
    });
    window.ace.dispatchEvent(trackingEvent);
  }
}

export const EventType = {
  LINK_OUT: 'link-out',
  SEARCH_CHANGE: 'search-change',
  DATE_CHANGE: 'date-change',
  LIST_RESULTS: 'list-results',
  PAGE_CHANGE: 'page-change',
  GEOLOCATION_REQUEST: 'geolocation-request',
  GEOLOCATION_ACQUIRED: 'geolocation-acquired',
}
