exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n}\n\n.VmNk7 {\n  position: relative;\n}\n\n._3SphL {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  text-align: center;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  padding: 32px;\n  font-size: 16px;\n}\n\n.CeZj9 {\n  color: #5fa943;\n}\n", ""]);

// exports
exports.locals = {
	"container": "VmNk7",
	"loader": "_3SphL",
	"spinner": "CeZj9"
};