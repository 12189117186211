import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { geolocShape, mapTheatersShape } from 'shared/shapes';
import { createMap } from 'utils/map';
import { GOOGLE_MAP_DEFAULT_ZOOM } from 'config/map';

import Markers from './Markers';

import css from './styles.css';

class GoogleMaps extends PureComponent {
  state = {
    mapInstance: undefined,
  };

  componentWillMount() {
    const { isMapScriptLoaded } = this.props;
    const { mapInstance } = this.state;

    if (isMapScriptLoaded && !mapInstance) {
      this.setMap();
    }
  }

  componentDidUpdate(prevProps) {
    const { location: { lat: prevLat, lng: prevLng } } = prevProps;
    const { location, location: { lat, lng }, isMapScriptLoaded } = this.props;
    const { mapInstance } = this.state;

    if (isMapScriptLoaded && !mapInstance) {
      this.setMap();
      return;
    }

    if (mapInstance && (prevLat !== lat && prevLng !== lng)) {
      this.centerMap(location);
    }
  }

  onMapReady = () => {
    const { location } = this.props;
    this.centerMap(location);
  };

  setMap = () => {
    const { mapOptions } = this.props;
    this.setState(
      { mapInstance: createMap(this.mapDiv, mapOptions) },
      this.onMapReady,
    );
  };

  centerMap = location => {
    const { mapInstance } = this.state;
    mapInstance.setCenter(location);
    mapInstance.setZoom(GOOGLE_MAP_DEFAULT_ZOOM);
  };

  render() {
    const { mapInstance } = this.state;
    const { markers, onMarkerSelected, activeMarkerId } = this.props;

    return (
      <div className={css.map} ref={div => (this.mapDiv = div)}>
        {mapInstance && (
          <Markers
            activeMarkerId={activeMarkerId}
            mapInstance={mapInstance}
            markers={markers}
            onClick={onMarkerSelected}
          />
        )}
      </div>
    );
  }
}

GoogleMaps.defaultProps = {
  isMapScriptLoaded: false,
  markers: [],
  onMarkerSelected: () => { },
  mapOptions: undefined,
};

GoogleMaps.propTypes = {
  activeMarkerId: PropTypes.string,
  isMapScriptLoaded: PropTypes.bool,
  location: geolocShape,
  markers: mapTheatersShape,
  onMarkerSelected: PropTypes.func,
  mapOptions: PropTypes.object,
};

export default GoogleMaps;
