import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { subDays, addDays } from 'date-fns';

import { convertUTCToLocal } from 'utils/date';
import { includes } from 'utils/array';
import geolocShape from 'shared/shapes/geoloc';
import GetMovie from 'shared/queries/GetMovie';
import { ELEMENT_KEYS } from 'shared/constants';

import {
  getSelectedItemIndex,
  getScrollIndex,
  getShortISOString,
} from './helpers';
import css from '../styles.css';

const TRANSITION_DELAY = 500;
const MODE = {
  MIDDLE: 'middle',
  LEFT: 'left',
  RIGHT: 'right',
};
const INTERACTION_TYPES = {
  CLICK: 'click',
};
const DEFAULT_STATE = {
  days: [],
  rolling: false,
  roller: 0,
  mode: MODE.MIDDLE,
  type: INTERACTION_TYPES.CLICK,
};

class ScrollCalendar extends Component {
  state = DEFAULT_STATE;

  componentWillMount() {
    this.generateDateList(this.props.selected);

    // initialize next / prev methods
    this.props.goNext(() => this.goTo(MODE.RIGHT));
    this.props.goPrev(() => this.goTo(MODE.LEFT));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) this.reset();
  }

  reset() {
    this.setState(DEFAULT_STATE, () => {
      this.generateDateList(this.props.selected);
      if (this.props.isReseted) this.props.isReseted();
    });
  }

  generateDateList = (date = new Date()) => {
    const { itemCount } = this.props;

    const daysBefore = Array.from({ length: itemCount })
      .map((_, i) => subDays(date, i + 1))
      .reverse();

    const daysAfter = Array.from({ length: itemCount }).map((_, i) =>
      addDays(date, i + 1),
    );

    const days = [...daysBefore, date, ...daysAfter];

    this.setState({ days, mode: MODE.MIDDLE });

    // add a render to unlock transition
    setTimeout(() => this.setState({ rolling: true }), 0);
  };

  goTo = to => {
    const { days, mode } = this.state;
    const { itemCount, dayWidth, scrollingIndex } = this.props;
    const scrolling = getScrollIndex({
      itemWidth: this.scrollerRef ? this.scrollerRef.offsetWidth : 0,
      dayWidth,
      scrollingIndex,
    });

    // check if transition is already run
    if (mode !== MODE.MIDDLE) return;

    this.setState({ mode: to, rolling: false });

    // wait end transition
    setTimeout(() => {
      const generateFrom =
        days[to === MODE.LEFT ? itemCount - scrolling : itemCount + scrolling];
      this.generateDateList(generateFrom);
    }, TRANSITION_DELAY);
  };

  render() {
    const { days, mode, type, rolling } = this.state;
    const {
      dayWidth,
      scrollingIndex,
      itemCount,
      render,
      selected,
      countries,
      movieId,
      search,
      radius,
      location,
    } = this.props;
    const { getElementStyle } = this.context;

    const approximateSearch = !!search;

    const scrolling = getScrollIndex({
      itemWidth: this.scrollerRef ? this.scrollerRef.offsetWidth : 0,
      dayWidth,
      scrollingIndex,
    });

    let marginLeft;
    let transition;
    let transitionRoller;

    const middleItemIndex = dayWidth * itemCount;
    const transitionType =
      type === INTERACTION_TYPES.CLICK ? 'ease-in-out' : 'linear';

    if (rolling) {
      transitionRoller = `${TRANSITION_DELAY}ms ${transitionType}`;
    }

    if (mode === MODE.MIDDLE) {
      marginLeft = `-${middleItemIndex}px`;
    } else if (mode === MODE.LEFT) {
      transition = `${TRANSITION_DELAY}ms ${transitionType}`;
      marginLeft = `-${middleItemIndex - dayWidth * scrolling}px`;
    } else if (mode === MODE.RIGHT) {
      transition = `${TRANSITION_DELAY}ms ${transitionType}`;
      marginLeft = `-${middleItemIndex + dayWidth * scrolling}px`;
    }

    const from = getShortISOString(days[0]);
    const to = getShortISOString(days[days.length - 1]);

    return (
      <GetMovie
        countries={countries}
        dateRange={{ from, to }}
        id={movieId}
        location={
          approximateSearch && location
            ? undefined
            : { lat: location.lat, lon: location.lng }
        }
        radius={approximateSearch ? undefined : radius}
        search={search}
        skip={!radius}
      >
        {({ showtimesDates = [] } = {}) => (
          <div
            ref={ref => (this.scrollerRef = ref)}
            className={css.scrolling}
            style={{ marginLeft, ...(transition ? { transition } : {}) }}
          >
            <div className={css.scrollingDays}>
              {days.map(day => {
                const offsettedDate = convertUTCToLocal(day);
                const isDisabled = showtimesDates
                  ? !includes(showtimesDates, getShortISOString(offsettedDate))
                  : true;
                return render(day, isDisabled);
              })}
            </div>
            <div className={css.rollingZone}>
              <div
                className={css.rollingBar}
                style={{
                  marginLeft: `${getSelectedItemIndex(days, selected) *
                    dayWidth}px`,
                  ...(transitionRoller ? { transition: transitionRoller } : {}),
                  backgroundColor: getElementStyle(
                    ELEMENT_KEYS.DATES_ITEM_SELECTED,
                  ).color,
                }}
              />
            </div>
          </div>
        )}
      </GetMovie>
    );
  }
}

ScrollCalendar.contextTypes = {
  getElementStyle: PropTypes.func.isRequired,
};

ScrollCalendar.propTypes = {
  itemCount: PropTypes.number,
  scrollingIndex: PropTypes.number,
  dayWidth: PropTypes.number,
  render: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
  isReseted: PropTypes.func,
  reset: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.string),
  movieId: PropTypes.string.isRequired,
  location: geolocShape,
  radius: PropTypes.number,
  search: PropTypes.string,
};

ScrollCalendar.defaultProps = {
  itemCount: 40,
  scrollingIndex: 10,
  dayWidth: 96,
};

export default ScrollCalendar;
