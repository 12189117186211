const AVAILABLE_LOCALES = {
  de_DE: 'de_DE',
  en_US: 'en_US',
  en_GB: 'en_GB',
  fr_FR: 'fr_FR',
  ja_JP: 'ja_JP',
};

const DEFAULT_LOCALE = AVAILABLE_LOCALES.en_US;

module.exports = {
  AVAILABLE_LOCALES,
  DEFAULT_LOCALE,
};
