// origins: http://techslides.com/demos/country-capitals.json

// eslint-disable-next-line import/prefer-default-export
export const countryShapes = [
  {
    name: 'AFGHANISTAN',
    capital: {
      lng: 69.183333,
      lat: 34.516666666666666,
    },
  },
  {
    name: 'ALAND_ISLANDS',
    capital: {
      lng: 19.9,
      lat: 60.116667,
    },
  },
  {
    name: 'ALBANIA',
    capital: {
      lng: 19.816667,
      lat: 41.31666666666667,
    },
  },
  {
    name: 'ALGERIA',
    capital: {
      lng: 3.05,
      lat: 36.75,
    },
  },
  {
    name: 'AMERICAN_SAMOA',
    capital: {
      lng: -170.7,
      lat: -14.266666666666667,
    },
  },
  {
    name: 'ANDORRA',
    capital: {
      lng: 1.516667,
      lat: 42.5,
    },
  },
  {
    name: 'ANGOLA',
    capital: {
      lng: 13.216667,
      lat: -8.833333333333334,
    },
  },
  {
    name: 'ANGUILLA',
    capital: {
      lng: -63.05,
      lat: 18.216666666666665,
    },
  },
  {
    name: 'ANTARCTICA',
    capital: {
      lng: 0,
      lat: 0,
    },
  },
  {
    name: 'ANTIGUA_AND_BARBUDA',
    capital: {
      lng: -61.85,
      lat: 17.116666666666667,
    },
  },
  {
    name: 'ARGENTINA',
    capital: {
      lng: -58.666667,
      lat: -34.583333333333336,
    },
  },
  {
    name: 'ARMENIA',
    capital: {
      lng: 44.5,
      lat: 40.166666666666664,
    },
  },
  {
    name: 'ARUBA',
    capital: {
      lng: -70.033333,
      lat: 12.516666666666667,
    },
  },
  {
    name: 'AUSTRALIA',
    capital: {
      lng: 149.133333,
      lat: -35.266666666666666,
    },
  },
  {
    name: 'AUSTRIA',
    capital: {
      lng: 16.366667,
      lat: 48.2,
    },
  },
  {
    name: 'AZERBAIJAN',
    capital: {
      lng: 49.866667,
      lat: 40.38333333333333,
    },
  },
  {
    name: 'BAHAMAS',
    capital: {
      lng: -77.35,
      lat: 25.083333333333332,
    },
  },
  {
    name: 'BAHRAIN',
    capital: {
      lng: 50.566667,
      lat: 26.233333333333334,
    },
  },
  {
    name: 'BANGLADESH',
    capital: {
      lng: 90.4,
      lat: 23.716666666666665,
    },
  },
  {
    name: 'BARBADOS',
    capital: {
      lng: -59.616667,
      lat: 13.1,
    },
  },
  {
    name: 'BELARUS',
    capital: {
      lng: 27.566667,
      lat: 53.9,
    },
  },
  {
    name: 'BELGIUM',
    capital: {
      lng: 4.333333,
      lat: 50.833333333333336,
    },
  },
  {
    name: 'BELIZE',
    capital: {
      lng: -88.766667,
      lat: 17.25,
    },
  },
  {
    name: 'BENIN',
    capital: {
      lng: 2.616667,
      lat: 6.483333333333333,
    },
  },
  {
    name: 'BERMUDA',
    capital: {
      lng: -64.783333,
      lat: 32.28333333333333,
    },
  },
  {
    name: 'BHUTAN',
    capital: {
      lng: 89.633333,
      lat: 27.466666666666665,
    },
  },
  {
    name: 'BOLIVIA',
    capital: {
      lng: -68.15,
      lat: -16.5,
    },
  },
  {
    name: 'BOSNIA_AND_HERZEGOVINA',
    capital: {
      lng: 18.416667,
      lat: 43.86666666666667,
    },
  },
  {
    name: 'BOTSWANA',
    capital: {
      lng: 25.9,
      lat: -24.633333333333333,
    },
  },
  {
    name: 'BRAZIL',
    capital: {
      lng: -47.916667,
      lat: -15.783333333333333,
    },
  },
  {
    name: 'BRITISH_INDIAN_OCEAN_TERRITORY',
    capital: {
      lng: 72.4,
      lat: -7.3,
    },
  },
  {
    name: 'BRITISH_VIRGIN_ISLANDS',
    capital: {
      lng: -64.616667,
      lat: 18.416666666666668,
    },
  },
  {
    name: 'BRUNEI_DARUSSALAM',
    capital: {
      lng: 114.933333,
      lat: 4.883333333333333,
    },
  },
  {
    name: 'BULGARIA',
    capital: {
      lng: 23.316667,
      lat: 42.68333333333333,
    },
  },
  {
    name: 'BURKINA_FASO',
    capital: {
      lng: -1.516667,
      lat: 12.366666666666667,
    },
  },
  {
    name: 'BURUNDI',
    capital: {
      lng: 29.35,
      lat: -3.3666666666666667,
    },
  },
  {
    name: 'CAMBODIA',
    capital: {
      lng: 104.916667,
      lat: 11.55,
    },
  },
  {
    name: 'CAMEROON',
    capital: {
      lng: 11.516667,
      lat: 3.8666666666666667,
    },
  },
  {
    name: 'CANADA',
    capital: {
      lng: -75.7,
      lat: 45.416666666666664,
    },
  },
  {
    name: 'CAPE_VERDE',
    capital: {
      lng: -23.516667,
      lat: 14.916666666666666,
    },
  },
  {
    name: 'CAYMAN_ISLANDS',
    capital: {
      lng: -81.383333,
      lat: 19.3,
    },
  },
  {
    name: 'CENTRAL_AFRICAN_REPUBLIC',
    capital: {
      lng: 18.583333,
      lat: 4.366666666666666,
    },
  },
  {
    name: 'CHAD',
    capital: {
      lng: 15.033333,
      lat: 12.1,
    },
  },
  {
    name: 'CHILE',
    capital: {
      lng: -70.666667,
      lat: -33.45,
    },
  },
  {
    name: 'CHINA',
    capital: {
      lng: 116.383333,
      lat: 39.916666666666664,
    },
  },
  {
    name: 'CHRISTMAS_ISLAND',
    capital: {
      lng: 105.716667,
      lat: -10.416666666666666,
    },
  },
  {
    name: 'COCOS_ISLANDS',
    capital: {
      lng: 96.833333,
      lat: -12.166666666666666,
    },
  },
  {
    name: 'COLOMBIA',
    capital: {
      lng: -74.083333,
      lat: 4.6,
    },
  },
  {
    name: 'COMOROS',
    capital: {
      lng: 43.233333,
      lat: -11.7,
    },
  },
  {
    name: 'COOK_ISLANDS',
    capital: {
      lng: -159.766667,
      lat: -21.2,
    },
  },
  {
    name: 'COSTA_RICA',
    capital: {
      lng: -84.083333,
      lat: 9.933333333333334,
    },
  },
  {
    name: "COTE_D'IVOIRE",
    capital: {
      lng: -5.266667,
      lat: 6.816666666666666,
    },
  },
  {
    name: 'CROATIA',
    capital: {
      lng: 16,
      lat: 45.8,
    },
  },
  {
    name: 'CUBA',
    capital: {
      lng: -82.35,
      lat: 23.116666666666667,
    },
  },
  {
    name: 'CURAÃ§AO',
    capital: {
      lng: -68.916667,
      lat: 12.1,
    },
  },
  {
    name: 'CYPRUS',
    capital: {
      lng: 33.366667,
      lat: 35.166666666666664,
    },
  },
  {
    name: 'CZECH_REPUBLIC',
    capital: {
      lng: 14.466667,
      lat: 50.083333333333336,
    },
  },
  {
    name: 'DEMOCRATIC_REPUBLIC_OF_THE_CONGO',
    capital: {
      lng: 15.3,
      lat: -4.316666666666666,
    },
  },
  {
    name: 'DENMARK',
    capital: {
      lng: 12.583333,
      lat: 55.666666666666664,
    },
  },
  {
    name: 'DJIBOUTI',
    capital: {
      lng: 43.15,
      lat: 11.583333333333334,
    },
  },
  {
    name: 'DOMINICA',
    capital: {
      lng: -61.4,
      lat: 15.3,
    },
  },
  {
    name: 'DOMINICAN_REPUBLIC',
    capital: {
      lng: -69.9,
      lat: 18.466666666666665,
    },
  },
  {
    name: 'ECUADOR',
    capital: {
      lng: -78.5,
      lat: -0.21666666666666667,
    },
  },
  {
    name: 'EGYPT',
    capital: {
      lng: 31.25,
      lat: 30.05,
    },
  },
  {
    name: 'EL_SALVADOR',
    capital: {
      lng: -89.2,
      lat: 13.7,
    },
  },
  {
    name: 'EQUATORIAL_GUINEA',
    capital: {
      lng: 8.783333,
      lat: 3.75,
    },
  },
  {
    name: 'ERITREA',
    capital: {
      lng: 38.933333,
      lat: 15.333333333333334,
    },
  },
  {
    name: 'ESTONIA',
    capital: {
      lng: 24.716667,
      lat: 59.43333333333333,
    },
  },
  {
    name: 'ETHIOPIA',
    capital: {
      lng: 38.7,
      lat: 9.033333333333333,
    },
  },
  {
    name: 'FALKLAND_ISLANDS',
    capital: {
      lng: -57.85,
      lat: -51.7,
    },
  },
  {
    name: 'FAROE_ISLANDS',
    capital: {
      lng: -6.766667,
      lat: 62,
    },
  },
  {
    name: 'FEDERATED_STATES_OF_MICRONESIA',
    capital: {
      lng: 158.15,
      lat: 6.916666666666667,
    },
  },
  {
    name: 'FIJI',
    capital: {
      lng: 178.416667,
      lat: -18.133333333333333,
    },
  },
  {
    name: 'FINLAND',
    capital: {
      lng: 24.933333,
      lat: 60.166666666666664,
    },
  },
  {
    name: 'FRANCE',
    capital: {
      lng: 2.333333,
      lat: 48.86666666666667,
    },
  },
  {
    name: 'FRENCH_POLYNESIA',
    capital: {
      lng: -149.566667,
      lat: -17.533333333333335,
    },
  },
  {
    name: 'FRENCH_SOUTHERN_AND_ANTARCTIC_LANDS',
    capital: {
      lng: 70.216667,
      lat: -49.35,
    },
  },
  {
    name: 'GABON',
    capital: {
      lng: 9.45,
      lat: 0.38333333333333336,
    },
  },
  {
    name: 'GEORGIA',
    capital: {
      lng: 44.833333,
      lat: 41.68333333333333,
    },
  },
  {
    name: 'GERMANY',
    capital: {
      lng: 13.4,
      lat: 52.516666666666666,
    },
  },
  {
    name: 'GHANA',
    capital: {
      lng: -0.216667,
      lat: 5.55,
    },
  },
  {
    name: 'GIBRALTAR',
    capital: {
      lng: -5.35,
      lat: 36.13333333333333,
    },
  },
  {
    name: 'GREECE',
    capital: {
      lng: 23.733333,
      lat: 37.983333333333334,
    },
  },
  {
    name: 'GREENLAND',
    capital: {
      lng: -51.75,
      lat: 64.18333333333334,
    },
  },
  {
    name: 'GRENADA',
    capital: {
      lng: -61.75,
      lat: 12.05,
    },
  },
  {
    name: 'GUAM',
    capital: {
      lng: 144.733333,
      lat: 13.466666666666667,
    },
  },
  {
    name: 'GUATEMALA',
    capital: {
      lng: -90.516667,
      lat: 14.616666666666667,
    },
  },
  {
    name: 'GUERNSEY',
    capital: {
      lng: -2.533333,
      lat: 49.45,
    },
  },
  {
    name: 'GUINEA',
    capital: {
      lng: -13.7,
      lat: 9.5,
    },
  },
  {
    name: 'GUINEA-BISSAU',
    capital: {
      lng: -15.583333,
      lat: 11.85,
    },
  },
  {
    name: 'GUYANA',
    capital: {
      lng: -58.15,
      lat: 6.8,
    },
  },
  {
    name: 'HAITI',
    capital: {
      lng: -72.333333,
      lat: 18.533333333333335,
    },
  },
  {
    name: 'HEARD_ISLAND_AND_MCDONALD_ISLANDS',
    capital: {
      lng: 0,
      lat: 0,
    },
  },
  {
    name: 'HONDURAS',
    capital: {
      lng: -87.216667,
      lat: 14.1,
    },
  },
  {
    name: 'HONG_KONG',
    capital: {
      lng: 0,
      lat: 0,
    },
  },
  {
    name: 'HUNGARY',
    capital: {
      lng: 19.083333,
      lat: 47.5,
    },
  },
  {
    name: 'ICELAND',
    capital: {
      lng: -21.95,
      lat: 64.15,
    },
  },
  {
    name: 'INDIA',
    capital: {
      lng: 77.2,
      lat: 28.6,
    },
  },
  {
    name: 'INDONESIA',
    capital: {
      lng: 106.816667,
      lat: -6.166666666666667,
    },
  },
  {
    name: 'IRAN',
    capital: {
      lng: 51.416667,
      lat: 35.7,
    },
  },
  {
    name: 'IRAQ',
    capital: {
      lng: 44.4,
      lat: 33.333333333333336,
    },
  },
  {
    name: 'IRELAND',
    capital: {
      lng: -6.233333,
      lat: 53.31666666666667,
    },
  },
  {
    name: 'ISLE_OF_MAN',
    capital: {
      lng: -4.483333,
      lat: 54.15,
    },
  },
  {
    name: 'ISRAEL',
    capital: {
      lng: 35.233333,
      lat: 31.766666666666666,
    },
  },
  {
    name: 'ITALY',
    capital: {
      lng: 12.483333,
      lat: 41.9,
    },
  },
  {
    name: 'JAMAICA',
    capital: {
      lng: -76.8,
      lat: 18,
    },
  },
  {
    name: 'JAPAN',
    capital: {
      lng: 139.75,
      lat: 35.68333333333333,
    },
  },
  {
    name: 'JERSEY',
    capital: {
      lng: -2.1,
      lat: 49.18333333333333,
    },
  },
  {
    name: 'JORDAN',
    capital: {
      lng: 35.933333,
      lat: 31.95,
    },
  },
  {
    name: 'KAZAKHSTAN',
    capital: {
      lng: 71.416667,
      lat: 51.166666666666664,
    },
  },
  {
    name: 'KENYA',
    capital: {
      lng: 36.816667,
      lat: -1.2833333333333332,
    },
  },
  {
    name: 'KIRIBATI',
    capital: {
      lng: 169.533333,
      lat: -0.8833333333333333,
    },
  },
  {
    name: 'KOSOVO',
    capital: {
      lng: 21.166667,
      lat: 42.666666666666664,
    },
  },
  {
    name: 'KUWAIT',
    capital: {
      lng: 47.966667,
      lat: 29.366666666666667,
    },
  },
  {
    name: 'KYRGYZSTAN',
    capital: {
      lng: 74.6,
      lat: 42.86666666666667,
    },
  },
  {
    name: 'LAOS',
    capital: {
      lng: 102.6,
      lat: 17.966666666666665,
    },
  },
  {
    name: 'LATVIA',
    capital: {
      lng: 24.1,
      lat: 56.95,
    },
  },
  {
    name: 'LEBANON',
    capital: {
      lng: 35.5,
      lat: 33.86666666666667,
    },
  },
  {
    name: 'LESOTHO',
    capital: {
      lng: 27.483333,
      lat: -29.316666666666666,
    },
  },
  {
    name: 'LIBERIA',
    capital: {
      lng: -10.8,
      lat: 6.3,
    },
  },
  {
    name: 'LIBYA',
    capital: {
      lng: 13.166667,
      lat: 32.88333333333333,
    },
  },
  {
    name: 'LIECHTENSTEIN',
    capital: {
      lng: 9.516667,
      lat: 47.13333333333333,
    },
  },
  {
    name: 'LITHUANIA',
    capital: {
      lng: 25.316667,
      lat: 54.68333333333333,
    },
  },
  {
    name: 'LUXEMBOURG',
    capital: {
      lng: 6.116667,
      lat: 49.6,
    },
  },
  {
    name: 'MACAU',
    capital: {
      lng: 0,
      lat: 0,
    },
  },
  {
    name: 'MACEDONIA',
    capital: {
      lng: 21.433333,
      lat: 42,
    },
  },
  {
    name: 'MADAGASCAR',
    capital: {
      lng: 47.516667,
      lat: -18.916666666666668,
    },
  },
  {
    name: 'MALAWI',
    capital: {
      lng: 33.783333,
      lat: -13.966666666666667,
    },
  },
  {
    name: 'MALAYSIA',
    capital: {
      lng: 101.7,
      lat: 3.1666666666666665,
    },
  },
  {
    name: 'MALDIVES',
    capital: {
      lng: 73.5,
      lat: 4.166666666666667,
    },
  },
  {
    name: 'MALI',
    capital: {
      lng: -8,
      lat: 12.65,
    },
  },
  {
    name: 'MALTA',
    capital: {
      lng: 14.5,
      lat: 35.88333333333333,
    },
  },
  {
    name: 'MARSHALL_ISLANDS',
    capital: {
      lng: 171.383333,
      lat: 7.1,
    },
  },
  {
    name: 'MAURITANIA',
    capital: {
      lng: -15.966667,
      lat: 18.066666666666666,
    },
  },
  {
    name: 'MAURITIUS',
    capital: {
      lng: 57.483333,
      lat: -20.15,
    },
  },
  {
    name: 'MEXICO',
    capital: {
      lng: -99.133333,
      lat: 19.433333333333334,
    },
  },
  {
    name: 'MOLDOVA',
    capital: {
      lng: 28.85,
      lat: 47,
    },
  },
  {
    name: 'MONACO',
    capital: {
      lng: 7.416667,
      lat: 43.733333333333334,
    },
  },
  {
    name: 'MONGOLIA',
    capital: {
      lng: 106.916667,
      lat: 47.916666666666664,
    },
  },
  {
    name: 'MONTENEGRO',
    capital: {
      lng: 19.266667,
      lat: 42.43333333333333,
    },
  },
  {
    name: 'MONTSERRAT',
    capital: {
      lng: -62.216667,
      lat: 16.7,
    },
  },
  {
    name: 'MOROCCO',
    capital: {
      lng: -6.816667,
      lat: 34.016666666666666,
    },
  },
  {
    name: 'MOZAMBIQUE',
    capital: {
      lng: 32.583333,
      lat: -25.95,
    },
  },
  {
    name: 'MYANMAR',
    capital: {
      lng: 96.15,
      lat: 16.8,
    },
  },
  {
    name: 'NAMIBIA',
    capital: {
      lng: 17.083333,
      lat: -22.566666666666666,
    },
  },
  {
    name: 'NAURU',
    capital: {
      lng: 166.920867,
      lat: -0.5477,
    },
  },
  {
    name: 'NEPAL',
    capital: {
      lng: 85.316667,
      lat: 27.716666666666665,
    },
  },
  {
    name: 'NETHERLANDS',
    capital: {
      lng: 4.916667,
      lat: 52.35,
    },
  },
  {
    name: 'NEW_CALEDONIA',
    capital: {
      lng: 166.45,
      lat: -22.266666666666666,
    },
  },
  {
    name: 'NEW_ZEALAND',
    capital: {
      lng: 174.783333,
      lat: -41.3,
    },
  },
  {
    name: 'NICARAGUA',
    capital: {
      lng: -86.25,
      lat: 12.133333333333333,
    },
  },
  {
    name: 'NIGER',
    capital: {
      lng: 2.116667,
      lat: 13.516666666666667,
    },
  },
  {
    name: 'NIGERIA',
    capital: {
      lng: 7.533333,
      lat: 9.083333333333334,
    },
  },
  {
    name: 'NIUE',
    capital: {
      lng: -169.916667,
      lat: -19.016666666666666,
    },
  },
  {
    name: 'NORFOLK_ISLAND',
    capital: {
      lng: 167.966667,
      lat: -29.05,
    },
  },
  {
    name: 'NORTH_KOREA',
    capital: {
      lng: 125.75,
      lat: 39.016666666666666,
    },
  },
  {
    name: 'NORTHERN_CYPRUS',
    capital: {
      lng: 33.366667,
      lat: 35.183333,
    },
  },
  {
    name: 'NORTHERN_MARIANA_ISLANDS',
    capital: {
      lng: 145.75,
      lat: 15.2,
    },
  },
  {
    name: 'NORWAY',
    capital: {
      lng: 10.75,
      lat: 59.916666666666664,
    },
  },
  {
    name: 'OMAN',
    capital: {
      lng: 58.583333,
      lat: 23.616666666666667,
    },
  },
  {
    name: 'PAKISTAN',
    capital: {
      lng: 73.05,
      lat: 33.68333333333333,
    },
  },
  {
    name: 'PALAU',
    capital: {
      lng: 134.633333,
      lat: 7.483333333333333,
    },
  },
  {
    name: 'PALESTINE',
    capital: {
      lng: 35.233333,
      lat: 31.766666666666666,
    },
  },
  {
    name: 'PANAMA',
    capital: {
      lng: -79.533333,
      lat: 8.966666666666667,
    },
  },
  {
    name: 'PAPUA_NEW_GUINEA',
    capital: {
      lng: 147.183333,
      lat: -9.45,
    },
  },
  {
    name: 'PARAGUAY',
    capital: {
      lng: -57.666667,
      lat: -25.266666666666666,
    },
  },
  {
    name: 'PERU',
    capital: {
      lng: -77.05,
      lat: -12.05,
    },
  },
  {
    name: 'PHILIPPINES',
    capital: {
      lng: 120.966667,
      lat: 14.6,
    },
  },
  {
    name: 'PITCAIRN_ISLANDS',
    capital: {
      lng: -130.083333,
      lat: -25.066666666666666,
    },
  },
  {
    name: 'POLAND',
    capital: {
      lng: 21,
      lat: 52.25,
    },
  },
  {
    name: 'PORTUGAL',
    capital: {
      lng: -9.133333,
      lat: 38.71666666666667,
    },
  },
  {
    name: 'PUERTO_RICO',
    capital: {
      lng: -66.116667,
      lat: 18.466666666666665,
    },
  },
  {
    name: 'QATAR',
    capital: {
      lng: 51.533333,
      lat: 25.283333333333335,
    },
  },
  {
    name: 'REPUBLIC_OF_CONGO',
    capital: {
      lng: 15.283333,
      lat: -4.25,
    },
  },
  {
    name: 'ROMANIA',
    capital: {
      lng: 26.1,
      lat: 44.43333333333333,
    },
  },
  {
    name: 'RUSSIA',
    capital: {
      lng: 37.6,
      lat: 55.75,
    },
  },
  {
    name: 'RWANDA',
    capital: {
      lng: 30.05,
      lat: -1.95,
    },
  },
  {
    name: 'SAINT_BARTHELEMY',
    capital: {
      lng: -62.85,
      lat: 17.883333333333333,
    },
  },
  {
    name: 'SAINT_HELENA',
    capital: {
      lng: -5.716667,
      lat: -15.933333333333334,
    },
  },
  {
    name: 'SAINT_KITTS_AND_NEVIS',
    capital: {
      lng: -62.716667,
      lat: 17.3,
    },
  },
  {
    name: 'SAINT_LUCIA',
    capital: {
      lng: -61,
      lat: 14,
    },
  },
  {
    name: 'SAINT_MARTIN',
    capital: {
      lng: -63.0822,
      lat: 18.0731,
    },
  },
  {
    name: 'SAINT_PIERRE_AND_MIQUELON',
    capital: {
      lng: -56.183333,
      lat: 46.766666666666666,
    },
  },
  {
    name: 'SAINT_VINCENT_AND_THE_GRENADINES',
    capital: {
      lng: -61.216667,
      lat: 13.133333333333333,
    },
  },
  {
    name: 'SAMOA',
    capital: {
      lng: -171.766667,
      lat: -13.816666666666666,
    },
  },
  {
    name: 'SAN_MARINO',
    capital: {
      lng: 12.416667,
      lat: 43.93333333333333,
    },
  },
  {
    name: 'SAO_TOME_AND_PRINCIPE',
    capital: {
      lng: 6.733333,
      lat: 0.3333333333333333,
    },
  },
  {
    name: 'SAUDI_ARABIA',
    capital: {
      lng: 46.7,
      lat: 24.65,
    },
  },
  {
    name: 'SENEGAL',
    capital: {
      lng: -17.633333,
      lat: 14.733333333333333,
    },
  },
  {
    name: 'SERBIA',
    capital: {
      lng: 20.5,
      lat: 44.833333333333336,
    },
  },
  {
    name: 'SEYCHELLES',
    capital: {
      lng: 55.45,
      lat: -4.616666666666667,
    },
  },
  {
    name: 'SIERRA_LEONE',
    capital: {
      lng: -13.233333,
      lat: 8.483333333333333,
    },
  },
  {
    name: 'SINGAPORE',
    capital: {
      lng: 103.85,
      lat: 1.2833333333333332,
    },
  },
  {
    name: 'SINT_MAARTEN',
    capital: {
      lng: -63.033333,
      lat: 18.016666666666666,
    },
  },
  {
    name: 'SLOVAKIA',
    capital: {
      lng: 17.116667,
      lat: 48.15,
    },
  },
  {
    name: 'SLOVENIA',
    capital: {
      lng: 14.516667,
      lat: 46.05,
    },
  },
  {
    name: 'SOLOMON_ISLANDS',
    capital: {
      lng: 159.95,
      lat: -9.433333333333334,
    },
  },
  {
    name: 'SOMALIA',
    capital: {
      lng: 45.333333,
      lat: 2.066666666666667,
    },
  },
  {
    name: 'SOMALILAND',
    capital: {
      lng: 44.05,
      lat: 9.55,
    },
  },
  {
    name: 'SOUTH_AFRICA',
    capital: {
      lng: 28.216667,
      lat: -25.7,
    },
  },
  {
    name: 'SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS',
    capital: {
      lng: -36.5,
      lat: -54.283333,
    },
  },
  {
    name: 'SOUTH_KOREA',
    capital: {
      lng: 126.983333,
      lat: 37.55,
    },
  },
  {
    name: 'SOUTH_SUDAN',
    capital: {
      lng: 31.616667,
      lat: 4.85,
    },
  },
  {
    name: 'SPAIN',
    capital: {
      lng: -3.683333,
      lat: 40.4,
    },
  },
  {
    name: 'SRI_LANKA',
    capital: {
      lng: 79.833333,
      lat: 6.916666666666667,
    },
  },
  {
    name: 'SUDAN',
    capital: {
      lng: 32.533333,
      lat: 15.6,
    },
  },
  {
    name: 'SURINAME',
    capital: {
      lng: -55.166667,
      lat: 5.833333333333333,
    },
  },
  {
    name: 'SVALBARD',
    capital: {
      lng: 15.633333,
      lat: 78.21666666666667,
    },
  },
  {
    name: 'SWAZILAND',
    capital: {
      lng: 31.133333,
      lat: -26.316666666666666,
    },
  },
  {
    name: 'SWEDEN',
    capital: {
      lng: 18.05,
      lat: 59.333333333333336,
    },
  },
  {
    name: 'SWITZERLAND',
    capital: {
      lng: 7.466667,
      lat: 46.916666666666664,
    },
  },
  {
    name: 'SYRIA',
    capital: {
      lng: 36.3,
      lat: 33.5,
    },
  },
  {
    name: 'TAIWAN',
    capital: {
      lng: 121.516667,
      lat: 25.033333333333335,
    },
  },
  {
    name: 'TAJIKISTAN',
    capital: {
      lng: 68.766667,
      lat: 38.55,
    },
  },
  {
    name: 'TANZANIA',
    capital: {
      lng: 39.283333,
      lat: -6.8,
    },
  },
  {
    name: 'THAILAND',
    capital: {
      lng: 100.516667,
      lat: 13.75,
    },
  },
  {
    name: 'THE_GAMBIA',
    capital: {
      lng: -16.566667,
      lat: 13.45,
    },
  },
  {
    name: 'TIMOR-LESTE',
    capital: {
      lng: 125.6,
      lat: -8.583333333333334,
    },
  },
  {
    name: 'TOGO',
    capital: {
      lng: 1.216667,
      lat: 6.116666666666666,
    },
  },
  {
    name: 'TOKELAU',
    capital: {
      lng: -171.833333,
      lat: -9.166667,
    },
  },
  {
    name: 'TONGA',
    capital: {
      lng: -175.2,
      lat: -21.133333333333333,
    },
  },
  {
    name: 'TRINIDAD_AND_TOBAGO',
    capital: {
      lng: -61.516667,
      lat: 10.65,
    },
  },
  {
    name: 'TUNISIA',
    capital: {
      lng: 10.183333,
      lat: 36.8,
    },
  },
  {
    name: 'TURKEY',
    capital: {
      lng: 32.866667,
      lat: 39.93333333333333,
    },
  },
  {
    name: 'TURKMENISTAN',
    capital: {
      lng: 58.383333,
      lat: 37.95,
    },
  },
  {
    name: 'TURKS_AND_CAICOS_ISLANDS',
    capital: {
      lng: -71.133333,
      lat: 21.466666666666665,
    },
  },
  {
    name: 'TUVALU',
    capital: {
      lng: 179.216667,
      lat: -8.516666666666667,
    },
  },
  {
    name: 'UGANDA',
    capital: {
      lng: 32.55,
      lat: 0.31666666666666665,
    },
  },
  {
    name: 'UKRAINE',
    capital: {
      lng: 30.516667,
      lat: 50.43333333333333,
    },
  },
  {
    name: 'UNITED_ARAB_EMIRATES',
    capital: {
      lng: 54.366667,
      lat: 24.466666666666665,
    },
  },
  {
    name: 'UNITED_KINGDOM',
    capital: {
      lng: -0.083333,
      lat: 51.5,
    },
  },
  {
    name: 'USA',
    capital: {
      lng: -77.0368707,
      lat: 38.9071923,
    },
  },
  {
    name: 'URUGUAY',
    capital: {
      lng: -56.166667,
      lat: -34.85,
    },
  },
  {
    name: 'US_MINOR_OUTLYING_ISLANDS',
    capital: {
      lng: 38.9071923,
      lat: -77.0368707,
    },
  },
  {
    name: 'US_VIRGIN_ISLANDS',
    capital: {
      lng: -64.933333,
      lat: 18.35,
    },
  },
  {
    name: 'UZBEKISTAN',
    capital: {
      lng: 69.25,
      lat: 41.31666666666667,
    },
  },
  {
    name: 'VANUATU',
    capital: {
      lng: 168.316667,
      lat: -17.733333333333334,
    },
  },
  {
    name: 'VATICAN_CITY',
    capital: {
      lng: 12.45,
      lat: 41.9,
    },
  },
  {
    name: 'VENEZUELA',
    capital: {
      lng: -66.866667,
      lat: 10.483333333333333,
    },
  },
  {
    name: 'VIETNAM',
    capital: {
      lng: 105.85,
      lat: 21.033333333333335,
    },
  },
  {
    name: 'WALLIS_AND_FUTUNA',
    capital: {
      lng: -171.933333,
      lat: -13.95,
    },
  },
  {
    name: 'WESTERN_SAHARA',
    capital: {
      lng: -13.203333,
      lat: 27.153611,
    },
  },
  {
    name: 'YEMEN',
    capital: {
      lng: 44.2,
      lat: 15.35,
    },
  },
  {
    name: 'ZAMBIA',
    capital: {
      lng: 28.283333,
      lat: -15.416666666666666,
    },
  },
  {
    name: 'ZIMBABWE',
    capital: {
      lng: 31.033333,
      lat: -17.816666666666666,
    },
  },
];
