exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n}\n\n.dJhs0 {\n  background-color: #ffffff;\n  border: 2px solid #5fa943;\n  border-radius: 6px;\n  width: 80px;\n  height: 40px;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-flex: 0;\n      -ms-flex: none;\n          flex: none;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  margin-left: 12px;\n  margin-top: 12px;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  color: #333333;\n  text-decoration: none\n}\n\n.dJhs0:visited,\n  .dJhs0:link {\n  color: #333333;\n}\n\n.dJhs0:hover {\n  text-decoration: none;\n  color: #ffffff;\n  background-color: #5fa943;\n}\n\n.mUEil {\n  line-height: 1;\n  font-size: 14px;\n}\n\n.TtMFq {\n  color: currentColor;\n  opacity: 0.5;\n  font-size: 10px;\n  line-height: 1;\n}\n\n._3wNXE {\n  pointer-events: none;\n  opacity: 0.5;\n}\n\n._10lLg {\n  pointer-events: none\n}\n\n._10lLg .mUEil {\n  opacity: 0.5;\n}\n", ""]);

// exports
exports.locals = {
	"schedule": "dJhs0",
	"hour": "mUEil",
	"version": "TtMFq",
	"scheduleDisabled": "_3wNXE dJhs0",
	"scheduleInactive": "_10lLg dJhs0"
};