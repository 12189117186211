// Thanks IE for that...

export const includes = (arr, value) =>
  arr.includes ? arr.includes(value) : arr.some(item => item === value);

export const find = (arr, cb) => {
  if (arr.find) return arr.find(cb);

  // eslint-disable-next-line
  for (let i = 0; i < arr.length; i++) {
    if (cb(arr[i], i, arr)) return arr[i];
  }
  return undefined;
};
