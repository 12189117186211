import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { defineMessages, intlShape } from 'react-intl';

import { ELEMENT_KEYS } from 'shared/constants';

import css from './styles.css';

const VERSION_KEYS = {
  FRENCH: 'FRENCH',
  GERMAN: 'GERMAN',
  ENGLISH: 'ENGLISH',
};

const messages = defineMessages({
  originalVersion: {
    id: 'theater-format-original-version-message',
    defaultMessage: 'OV',
  },
  [VERSION_KEYS.FRENCH]: {
    id: 'theater-format-french-version-message',
    defaultMessage: 'French',
  },
  [VERSION_KEYS.GERMAN]: {
    id: 'theater-format-german-version-message',
    defaultMessage: 'German',
  },
  [VERSION_KEYS.ENGLISH]: {
    id: 'theater-format-english-version-message',
    defaultMessage: 'English',
  },
});


class Schedule extends PureComponent {
  state = {
    isHovered: false,
  };

  render() {
    const {
      disabled,
      hour,
      version,
      movieLanguages,
      ticketingUrl,
      onClick,
    } = this.props;
    const { intl, getElementStyle } = this.context;
    const { isHovered } = this.state;

    const translatedVersion = VERSION_KEYS[version]
      ? intl.formatMessage(messages[version])
      : version;

    const styles = getElementStyle(ELEMENT_KEYS.SCHEDULES_BUTTON);
    const hoveredStyles = {
      ...styles,
      ...getElementStyle(ELEMENT_KEYS.SCHEDULES_BUTTON_HOVER),
    };

    return (
      <a
        href={ticketingUrl}
        target={'_blank'}
        rel={'noopener noreferrer'}
        className={classnames({
          [css.schedule]: !disabled && ticketingUrl,
          [css.scheduleInactive]: !ticketingUrl,
          [css.scheduleDisabled]: disabled,
        })}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
        onClick={onClick}
        style={isHovered ? hoveredStyles : styles}
      >
        <span className={css.hour}>{hour}</span>
        {version && (
          <span className={css.version}>
            {version === movieLanguages[0]
              ? intl.formatMessage(messages.originalVersion)
              : translatedVersion}
          </span>
        )
        }
      </a>
    );
  }
}

Schedule.contextTypes = {
  intl: intlShape.isRequired,
  getElementStyle: PropTypes.func.isRequired,
};

Schedule.defaultProps = {
  disabled: false,
  hour: undefined,
  version: undefined,
  movieLanguages: [],
  ticketingUrl: undefined,
  onClick: () => { },
};

Schedule.propTypes = {
  disabled: PropTypes.bool,
  hour: PropTypes.string,
  version: PropTypes.string,
  movieLanguages: PropTypes.arrayOf(PropTypes.string),
  ticketingUrl: PropTypes.string,
  onClick: PropTypes.func,
};

export default Schedule;
