/* eslint-disable no-console */
import merge from 'lodash/merge';

export const getValueFromEnv = (env, key, isJSON) => {
  try {
    const value = env[key] || undefined;
    if (isJSON && value) {
      return JSON.parse(value);
    }
    return value;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const getValueFromUrl = (params, key, isJSON) => {
  try {
    const value = params.get(key);
    const decodedValue = value ? decodeURIComponent(value) : undefined;
    if (isJSON && value) {
      return JSON.parse(decodedValue);
    }
    return decodedValue;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const getParamsFromEnv = () => {
  const params = {};

  params.countries = getValueFromEnv(process.env, 'countries');
  params.googleApiKey = getValueFromEnv(process.env, 'googleApiKey');
  params.googleAnalytics = getValueFromEnv(process.env, 'googleAnalytics');
  params.locale = getValueFromEnv(process.env, 'locale');
  params.movieId = getValueFromEnv(process.env, 'movieId');
  params.numberOfTheaters = getValueFromEnv(process.env, 'numberOfTheaters');
  params.startDate = getValueFromEnv(process.env, 'startDate');
  params.styles = getValueFromEnv(process.env, 'styles', true);
  params.token = getValueFromEnv(process.env, 'token');

  return params;
};

export const getParamsFromUrlSearchParams = () => {
  const urlParams = new URLSearchParams(document.location.search);
  const params = {};

  params.countries = getValueFromUrl(urlParams, 'countries');
  params.googleApiKey = getValueFromUrl(urlParams, 'googleApiKey');
  params.googleAnalytics = getValueFromUrl(urlParams, 'googleAnalytics');
  params.locale = getValueFromUrl(urlParams, 'locale');
  params.movieId = getValueFromUrl(urlParams, 'movieId');
  params.numberOfTheaters = getValueFromUrl(urlParams, 'numberOfTheaters');
  params.startDate = getValueFromUrl(urlParams, 'startDate');
  params.styles = getValueFromUrl(urlParams, 'styles', true);
  params.token = getValueFromUrl(urlParams, 'token');

  return params;
};

export const getParams = () => {
  const paramsFromEnv = getParamsFromEnv();
  const paramsFromUrl = getParamsFromUrlSearchParams();

  const source = {
    countries: undefined,
    googleApiKey: undefined,
    locale: undefined,
    movieId: undefined,
    numberOfTheaters: undefined,
    startDate: undefined,
    styles: undefined,
    token: undefined,
  };

  const params = merge(source, paramsFromEnv, paramsFromUrl);

  return params;
};

export const areParamsValid = params => {
  return (
    params.movieId !== '' &&
    params.countries !== '' &&
    params.locale !== '' &&
    params.startDate !== ''
  );
};
