import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Main from 'components/Main';
import { getElementStyle } from 'shared/helpers/styles';
import GetPosition from 'shared/components/GetPosition';

class Root extends PureComponent {
  getChildContext() {
    const { styles } = this.props;
    return {
      getElementStyle: elementKey => getElementStyle(elementKey, styles),
    };
  }

  render() {
    const {
      defaultPosition,
      saveUserGeoloc,
      appLocale,
      date,
      countries,
      movieId,
      googleApiKey,
      numberOfTheaters,
      mapOptions,
      analyzer,
    } = this.props;

    return (
      <GetPosition
        defaultPosition={defaultPosition}
        saveUserGeoloc={saveUserGeoloc}
      >
        {(userGeoloc, { openPrompt, promptIsOpen }) => (
          <Main
            analyzer={analyzer}
            userGeoloc={userGeoloc}
            openGeolocPrompt={openPrompt}
            appLocale={appLocale}
            geolocPromptIsOpen={promptIsOpen}
            date={date}
            countries={countries}
            movieId={movieId}
            googleApiKey={googleApiKey}
            numberOfTheaters={numberOfTheaters}
            mapOptions={mapOptions}
          />
        )}
      </GetPosition>
    );
  }
}

Root.childContextTypes = {
  getElementStyle: PropTypes.func,
};

Root.propTypes = {
  appLocale: PropTypes.string.isRequired,
  saveUserGeoloc: PropTypes.bool,
  defaultPosition: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  analyzer: PropTypes.string,
  date: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string),
  movieId: PropTypes.string.isRequired,
  googleApiKey: PropTypes.string.isRequired,
  styles: PropTypes.arrayOf(PropTypes.object),
  mapOptions: PropTypes.object,
};

Root.defaultProps = {
  countries: ['FRANCE'],
  analyzer: undefined,
};

export default Root;
