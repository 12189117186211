/* global google */
import {
  GOOGLE_MAP_DEFAULT_ZOOM,
  GOOGLE_MAP_STYLES,
  GOOGLE_MAPS_CALLBACK,
  GOOGLE_MAPS_URL,
} from 'config/map';
import { find } from 'utils/array';
import { browserIsIE11 } from 'utils/browser';
import { countryShapes } from 'config/countriesGeo';

const SVG_TEXT_OFFSETS = {
  1: '39%',
  2: '29%',
  3: '19%',
};

// radius of the earth in km
export const EARTH_RADIUS = 6378.8;
const EARTH_RADIAN_RATIO = 57.2958;

const CACHED_MARKERS = {};

let CACHED_MAP_INSTANCE = null;

/**
 * Get gmap script url
 * @param {string} googleApiKey google api key
 * @returns {string} Google maps URL
 */
export const gmapScriptUrl = googleApiKey =>
  `${GOOGLE_MAPS_URL}?key=${googleApiKey}&callback=${GOOGLE_MAPS_CALLBACK}`;

/**
 * Create a map with default config settings (overridable)
 * @param {domNode} target - A dom node where the map will hook
 * @param {object} options - Google maps options
 * @return {object} Google maps instance
 */
export const createMap = (target, options = {}) => {
  CACHED_MAP_INSTANCE = new google.maps.Map(target, {
    zoom: GOOGLE_MAP_DEFAULT_ZOOM,
    styles: GOOGLE_MAP_STYLES,
    streetViewControl: false,
    mapTypeControlOptions: { mapTypeIds: [] },
    maxZoom: 15,
    ...options,
  });
  return CACHED_MAP_INSTANCE;
};

export const getMapInstance = () => {
  return CACHED_MAP_INSTANCE;
};

/**
 * Convert coordinates intos Gmaps latlng instance
 * @param {object} coordinates - A coordinates shape
 * @param {float} coordinates.latitude - Latitude
 * @param {float} coordinates.longitude  - Longitude
 * @return {Object} Gmap latLng instance
 */
export const getLatLng = ({ lat, lng }) => new google.maps.LatLng(lat, lng);

/**
 * Convert coordinates intos Gmaps latlng instance
 * @param {array} coords - An array of coordianatese
 * @return {Object} Bounds
 */
export const getBounds = (coords = []) => {
  const bounds = new google.maps.LatLngBounds();
  for (let index = 0; index < coords.length; index += 1) {
    bounds.extend(getLatLng(coords[index]));
  }

  return bounds;
};

export const getGeoFromCountry = country => {
  const countryResult = find(countryShapes, ({ name }) => name === country);
  if (!countryResult) return undefined;
  return countryResult.capital;
};

/**
 * Define the most accurate map center
 * @param {object} userGeoloc - Contains user coordinates
 * @param {object} userGeoloc.lat - User latitude
 * @param {object} userGeoloc.lng - User longitude
 * @param {array} countries - Countries list
 * @return {object} geoloc - Coordiantes
 * @return {object} geoloc.lat - Latitude coordiantes
 * @return {object} geoloc.lng - Longitude coordiantes
 */
export const getInitialCenter = (userGeoloc, countries) => {
  const geo = userGeoloc;
  if (geo) return geo;

  const geoCountry = countries.length && getGeoFromCountry(countries[0]);

  if (!geoCountry) {
    // eslint-disable-next-line no-console
    console.error(`Country ${countries && countries[0]} not found on ace.`);
    return getGeoFromCountry('FRANCE');
  }

  return { lat: geoCountry.lat, lng: geoCountry.lng };
};

/**
 * Create a map marker object
 * @param {object} coords - Coordinates object
 * @param {object} coords.lat - Marker latitude
 * @param {object} coords.lng - Marker longitude
 * @param {string} icon - Icon path
 * @param {array} shape - Icon shape
 * @return {markerInstance} markerInstance - A map marker instance
 */
export const createMarker = (coords, icon = null, shape = null) =>
  new google.maps.Marker({
    position: getLatLng(coords),
    icon,
    shape,
    clickable: false,
  });

/**
 * Add marker to a map
 * @param {object} marker - Google map marker instance
 * @param {object} mapInstance - Google map instance
 * @return {undefined}
 */
export const addMarker = (marker, mapInstance) => marker.setMap(mapInstance);

/**
 * Remove marker to a map instance
 * @param {object} marker - Google map marker instance
 * @return {undefined}
 */
export const removeMarker = marker => marker.setMap(null);

/**
 * Generates a google maps point
 * @param {number} x - The x point
 * @param {number} y - The y point
 * @return {object} A google maps point instance
 */
export const getPoint = (x, y) => new google.maps.Point(x, y);

/**
/**
 * Generates a google maps size
 * @param {number} x - The x point
 * @param {number} y - The y point
 * @return {object} A google maps size instance
 */
export const getSize = (x, y) => new google.maps.Size(x, y);

/**
 * Generate icon for google map marker
 * @param {string} label - Icon content
 * @param {object} options - Icon options
 * @param {string} options.color - Icon color
 * @param {string} options.font - Font
 * @param {function} callback - Callback returning icon and shape
 * @return {undefined}
 */
export const generateMapMarkerIcon = (label = '', options = {}) => {
  const stringifiedOptions = JSON.stringify(options);

  return new Promise(resolve => {
    const cachedMarker =
      CACHED_MARKERS[label] && CACHED_MARKERS[label][stringifiedOptions]
        ? CACHED_MARKERS[label][stringifiedOptions]
        : undefined;
    if (cachedMarker) {
      resolve(cachedMarker.icon, cachedMarker.shape);
    }

    // Offsetting the label according to its lenght
    const svgTextCenter =
      SVG_TEXT_OFFSETS[label.toString().length] || SVG_TEXT_OFFSETS[3];

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const imageObj = new Image();

    const figure = document.createElement('svg');
    figure.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    figure.setAttribute('width', options.width);
    figure.setAttribute('height', options.height);

    const circle = document.createElement('path');
    circle.setAttribute('d', options.svgShape);
    circle.setAttribute('fill', options.backgroundColor);

    const text = document.createElement('text');
    text.setAttribute(
      'style',
      `fill: ${options.color};font: ${options.font}; text-align: 'center'`,
    );
    text.setAttribute('x', `${svgTextCenter}`);
    text.setAttribute('y', '55%');
    text.innerHTML = label;

    figure.appendChild(circle);
    figure.appendChild(text);

    const DOMURL = window.URL || window.webkitURL || window;
    const svg = new Blob([figure.outerHTML], { type: 'image/svg+xml' });
    const url = DOMURL.createObjectURL(svg);

    imageObj.setAttribute('crossOrigin', 'anonymous');
    imageObj.src = browserIsIE11 ? options.src : url;

    imageObj.onload = () => {
      context.drawImage(imageObj, 0, 0);

      if (browserIsIE11) {
        context.font = options.font;
        context.fillStyle = options.color;
        context.textAlign = 'center';
        context.fillText(label, options.width / 2, options.labelOffsetY);
      }

      let canvasUrl;

      try {
        canvasUrl = canvas.toDataURL();
      } catch (error) {
        return resolve();
      }

      const icon = {
        url: canvasUrl,
        size: new google.maps.Size(options.width, options.height),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(options.width / 2, options.height),
      };

      const shape = {
        coords: options.shape,
        type: 'poly',
      };

      if (!CACHED_MARKERS[label]) {
        CACHED_MARKERS[label] = {};
      }

      CACHED_MARKERS[label][stringifiedOptions] = { icon, shape };

      DOMURL.revokeObjectURL(url);

      return resolve(icon, shape);
    };
  });
};

export const getBoundsRadius = (bounds, center) => {
  // degrees to radians (divide by EARTH_RADIAN_RATIO)
  const neLat = bounds.getNorthEast().lat() / EARTH_RADIAN_RATIO;
  const neLng = bounds.getNorthEast().lng() / EARTH_RADIAN_RATIO;
  const swLat = bounds.getSouthWest().lat() / EARTH_RADIAN_RATIO;
  const swLng = bounds.getSouthWest().lng() / EARTH_RADIAN_RATIO;
  const centerLat = center.lat / EARTH_RADIAN_RATIO;
  const centerLong = center.lng / EARTH_RADIAN_RATIO;
  // distance = circle radius from center to Northeast corner of bounds
  const eastRadius =
    EARTH_RADIUS *
    Math.acos(
      Math.sin(centerLat) * Math.sin(neLat) +
        Math.cos(centerLat) * Math.cos(neLat) * Math.cos(neLng - centerLong),
    );
  const westRadius =
    EARTH_RADIUS *
    Math.acos(
      Math.sin(centerLat) * Math.sin(swLat) +
        Math.cos(centerLat) * Math.cos(swLat) * Math.cos(swLng - centerLong),
    );
  return Math.round(Math.max(eastRadius, westRadius)) * 1000;
};

export const getCurrentCenter = () => {
  if (!CACHED_MAP_INSTANCE) {
    return null;
  }

  return CACHED_MAP_INSTANCE.getCenter();
};
