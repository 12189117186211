import EventTarget from '@ungap/event-target';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';

import ApolloProvider from 'utils/apollo/ApolloProvider';
import { getStandardizedLocale, analyzerFromLocale } from 'utils/intl';
import postHeight from 'shared/helpers/postHeight';

import deDEIntl from 'translations/de_DE.json';
import enGBIntl from 'translations/en_GB.json';
import enUSIntl from 'translations/en_US.json';
import frFRIntl from 'translations/fr_FR.json';
import jpJPIntl from 'translations/ja_JP.json';

import { EventType } from './utils/tracking';

import Root from './Root';

const INTL_FILES = {
  de_DE: deDEIntl,
  en_GB: enGBIntl,
  en_US: enUSIntl,
  fr_FR: frFRIntl,
  ja_JP: jpJPIntl,
};

const loadLocaleMessages = locale => {
  const normalizedLocale = getStandardizedLocale(locale);
  return INTL_FILES[normalizedLocale];
};

const render = (Component, target, config) => {
  if (!config.movieId) return;

  // We give 'en' to intlProvider for skipping warning (not an important param)
  // Messages are loaded depending the config.locale
  const intlProviderLocale = 'en';

  const app = (
    <ApolloProvider config={config}>
      <IntlProvider
        locale={intlProviderLocale}
        messages={config.localeMessages}
      >
        <Component
          analyzer={
            config.analyzer
              ? config.analyzer
              : analyzerFromLocale(config.locale)
          }
          appLocale={getStandardizedLocale(config.locale)}
          userGeoloc={config.userGeoloc}
          saveUserGeoloc={config.saveUserGeoloc}
          countries={config.countries}
          movieId={config.movieId}
          numberOfTheaters={config.numberOfTheaters}
          date={config.date}
          googleApiKey={config.googleApiKey}
          styles={config.styles}
          mapOptions={config.mapOptions}
        />
      </IntlProvider>
    </ApolloProvider>
  );

  ReactDOM.render(app, target);
};

// prepare polyfills and render components
const init = (target, config = {}) => {
  if (window) {
    window.ace.config = config;
    window.ace.target = target;
  }

  postHeight();

  const googleApiKey = config.googleApiKey;

  if (!googleApiKey) {
    // eslint-disable-next-line no-console
    console.error(
      `A valid 'googleApiKey' is required in the configuration object.`,
    );
    return;
  }

  if (!config.token) {
    // eslint-disable-next-line no-console
    console.error('A valid token is required in the configuration object.');
    return;
  }

  render(Root, target, {
    ...config,
    googleApiKey,
    localeMessages: loadLocaleMessages(config.locale),
  });
};

if (window && window.Event && window.dispatchEvent) {
  const readyEvent = new CustomEvent('aceWidgetReady');
  window.dispatchEvent(readyEvent);
}

if (window) {
  const widget = new EventTarget();
  widget.init = init;
  widget.EventType = EventType;
  window.ace = widget;
}

export default init;
