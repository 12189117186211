import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape } from 'react-intl';
import isBefore from 'date-fns/is_before';

import { CLIENT_CONTEXT_TYPE } from 'utils/apollo/ApolloQueryPromise';
import { getInitialCenter, getCurrentCenter, EARTH_RADIUS } from 'utils/map';
import { getRange, convertLocalToUTC } from 'utils/date';
import { browserIsIE11 } from 'utils/browser';
import geolocShape from 'shared/shapes/geoloc';
import GetMovie from 'shared/queries/GetMovie';
import { ELEMENT_KEYS } from 'shared/constants';

import tracking, { EventType } from '../../utils/tracking';
import MapComponent from '../Map';
import SearchBar from '../SearchBar';
import Calendar from '../Calendar';
import Theaters from '../Theaters';
import DatePicker from '../DatePicker';

import css from './styles.css';

const messages = defineMessages({
  getPosition: {
    id: 'main-bar-aquisition-message',
    defaultMessage: 'Acquiring position',
  },
});
const DEBOUNCE_TIME = 300;

const MAX_THEATERS = 1000;

class Main extends PureComponent {
  constructor(props, context) {
    super(props, context);

    const today = convertLocalToUTC(new Date());
    const startDate = isBefore(props.date, today) ? today : props.date;

    this.state = {
      search: undefined,
      datePickerOpen: false,
      resetCalendar: false,
      initialStartDate: convertLocalToUTC(new Date(startDate)),
      date: convertLocalToUTC(new Date(startDate)),
      dateRange: getRange(startDate),
      geolocSelected: getInitialCenter(props.userGeoloc, props.countries),
      selectedTheaterId: undefined,
      datesActive: {},
      pattern: '',
      debouncedPattern: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { geolocSelected } = this.state;
    if (
      nextProps.userGeoloc &&
      (nextProps.userGeoloc.lat !== geolocSelected.lat ||
        nextProps.userGeoloc.lng !== geolocSelected.lng)
    ) {
      this.setState({
        geolocSelected: nextProps.userGeoloc,
      });
    }
  }

  onToggleDatePicker = (datePickerOpen = false) =>
    this.setState({ datePickerOpen });

  onSelectSearchResult = result => {
    const center = getCurrentCenter();
    const { value, item: { coordinates } = {} } = result;

    // Use the selected item coordinates,
    // or fallback to map center.
    const geolocSelected = coordinates || {
      lat: center.lat(),
      lng: center.lng(),
    };

    this.setState({
      search: value,
      debouncedPattern: value,
      pattern: value,
      selectedTheaterId: undefined,
      geolocSelected,
    });
  };

  setPattern = pattern => {
    this.setState({ pattern });

    if (this.deboucer) clearTimeout(this.deboucer);
    this.deboucer = setTimeout(
      () => this.setState({ debouncedPattern: pattern }),
      DEBOUNCE_TIME,
    );
  };

  setSelectedTheater = selectedTheaterId => {
    this.setState({ selectedTheaterId });
  };

  setDate = date => {
    this.setState(
      {
        date,
        dateRange: getRange(date),
        selectedTheaterId: undefined,
      },
      () => {
        tracking(EventType.DATE_CHANGE, {
          date: this.state.dateRange.from.split(' ')[0],
        });
      },
    );
  };

  openGeolocPrompt = () => {
    const { openGeolocPrompt } = this.props;

    this.setState(
      {
        selectedTheaterId: undefined,
        search: undefined,
        pattern: '',
        debouncedPattern: '',
      },
      openGeolocPrompt,
    );
  };

  render() {
    const { intl, getElementStyle } = this.context;

    const {
      appLocale,
      geolocPromptIsOpen,
      movieId,
      countries,
      numberOfTheaters,
      googleApiKey,
      radius,
      mapOptions,
      analyzer,
    } = this.props;

    const {
      datePickerOpen,
      date,
      resetCalendar,
      dateRange,
      geolocSelected,
      datesActive,
      search,
      debouncedPattern,
      pattern,
      selectedTheaterId,
      initialStartDate,
    } = this.state;

    return (
      <GetMovie id={movieId}>
        {({ languages: movieLanguages } = {}) => (
          <div className={css.main} style={getElementStyle(ELEMENT_KEYS.MAIN)}>
            {geolocPromptIsOpen && !browserIsIE11 && (
              <div className={css.aquisitionPosition}>
                {intl.formatMessage(messages.getPosition)}
              </div>
            )}
            <MapComponent
              googleApiKey={googleApiKey}
              movieId={movieId}
              countries={countries}
              dateRange={dateRange}
              location={geolocSelected}
              radius={radius}
              maxTheaters={MAX_THEATERS}
              search={search}
              selectedTheaterId={selectedTheaterId}
              mapOptions={mapOptions}
            />
            {datePickerOpen && (
              <DatePicker
                appLocale={appLocale}
                onClose={() => this.onToggleDatePicker(false)}
                date={date}
                onDate={newDate => {
                  this.setState({ resetCalendar: true });
                  this.setDate(newDate);
                }}
              />
            )}
            <SearchBar
              onSelect={this.onSelectSearchResult}
              openGeolocPrompt={this.openGeolocPrompt}
              countries={countries}
              pattern={pattern}
              debouncedPattern={debouncedPattern}
              setPattern={this.setPattern}
              movieId={movieId}
              startDate={initialStartDate}
              radius={radius}
              analyzer={analyzer}
            />
            <Calendar
              appLocale={appLocale}
              date={date}
              datesActive={datesActive}
              onDate={this.setDate}
              onOpenDatePicker={() => this.onToggleDatePicker(true)}
              reset={resetCalendar}
              isReseted={() => this.setState({ resetCalendar: false })}
              countries={countries}
              movieId={movieId}
              location={geolocSelected}
              radius={radius}
              search={search}
            />
            <Theaters
              appLocale={appLocale}
              key={dateRange && dateRange.from}
              movieId={movieId}
              search={search}
              movieLanguages={movieLanguages}
              countries={countries}
              dateRange={dateRange}
              location={geolocSelected}
              numberOfTheaters={numberOfTheaters}
              radius={radius}
              maxTheaters={MAX_THEATERS}
              onBadgeClick={this.setSelectedTheater}
            />
          </div>
        )}
      </GetMovie>
    );
  }
}

Main.contextTypes = {
  client: CLIENT_CONTEXT_TYPE,
  intl: intlShape.isRequired,
};

Main.defaultProps = {
  radius: EARTH_RADIUS * 100,
};

Main.propTypes = {
  appLocale: PropTypes.string.isRequired,
  userGeoloc: geolocShape,
  date: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string),
  numberOfTheaters: PropTypes.number,
  movieId: PropTypes.string,
  googleApiKey: PropTypes.string.isRequired,
  radius: PropTypes.number,
  mapOptions: PropTypes.object,
  analyzer: PropTypes.string,
};

Main.contextTypes = {
  client: CLIENT_CONTEXT_TYPE,
  intl: intlShape.isRequired,
  getElementStyle: PropTypes.func.isRequired,
};

export default Main;
