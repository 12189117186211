import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape } from 'react-intl';

import LoaderCss from 'components/icons/LoaderCss';
import { ELEMENT_KEYS } from 'shared/constants';

import css from './style.css';

const messages = defineMessages({
  loading: {
    id: 'theaters-loading-message',
    defaultMessage: 'Loading theaters and showtimes',
  },
});

const LoadingWrapper = ({ loading, children }, { intl, getElementStyle }) => (
  <div className={css.container}>
    {loading && (
      <div className={css.loader}>
        <div
          className={css.spinner}
          style={getElementStyle(ELEMENT_KEYS.THEATERS_LOADER)}
        >
          <LoaderCss />
        </div>
        <span className={css.text}>{intl.formatMessage(messages.loading)}</span>
      </div>
    )}
    {children}
  </div>
);

LoadingWrapper.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

LoadingWrapper.contextTypes = {
  intl: intlShape.isRequired,
  getElementStyle: PropTypes.func,
};

export default LoadingWrapper;
