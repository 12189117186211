import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GetTheaters from './queries/GetTheaters';
import TheatersComponent from './Component';
import { THEATERS_COUNT } from './constants';

class Theaters extends PureComponent {
  state = {
    pageIndex: 0,
    cursors: [undefined],
  };

  componentWillReceiveProps(nextProps) {
    const { dateRange, search, location } = this.props;
    const { dateRange: nextDateRange, search: nextSearch, location: nextLocation } = nextProps;

    if (
      nextDateRange !== dateRange ||
      nextSearch !== search ||
      (nextLocation.lng !== location.lng && nextLocation.lat !== location.lat)
    ) {
      // reset pagination
      this.setState({ pageIndex: 0, cursors: [undefined] });
    }
  }

  onUpdatePageInfos = state => this.setState(state);

  render() {
    const {
      appLocale,
      movieId,
      movieLanguages,
      dateRange,
      location,
      radius,
      countries,
      maxTheaters,
      numberOfTheaters,
      search,
      onBadgeClick,
    } = this.props;
    const { cursors, pageIndex } = this.state;

    return (
      <GetTheaters
        first={numberOfTheaters || THEATERS_COUNT}
        after={cursors[pageIndex]}
        movieId={movieId}
        dateRange={dateRange}
        countries={countries}
        maxTheaters={maxTheaters}
        location={location}
        radius={radius}
        search={search}
      >
        {(theatersShowtimes, { loading, hasNextPage, endCursor }) => (
          <TheatersComponent
            numberOfTheaters={numberOfTheaters}
            onUpdatePageInfos={this.onUpdatePageInfos}
            appLocale={appLocale}
            radius={radius}
            loading={loading}
            hasNextPage={hasNextPage}
            endCursor={endCursor}
            onBadgeClick={onBadgeClick}
            theatersShowtimes={theatersShowtimes}
            movieLanguages={movieLanguages}
            pageIndex={pageIndex}
            cursors={cursors}
            dateRange={dateRange}
          />
        )}
      </GetTheaters>
    );
  }
}

Theaters.propTypes = {
  appLocale: PropTypes.string.isRequired,
  numberOfTheaters: PropTypes.number,
  movieId: PropTypes.string,
  movieLanguages: PropTypes.arrayOf(PropTypes.string),
  search: PropTypes.string,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  radius: PropTypes.number,
  maxTheaters: PropTypes.number,
  onBadgeClick: PropTypes.func.isRequired,
};

export default Theaters;
