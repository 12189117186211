import { AVAILABLE_LOCALES, DEFAULT_LOCALE } from 'config/intl';
import PropTypes from 'prop-types';

/**
 * Standardize a string with a locale format to a project available locale
 * @param {string} locale - String looking like a locale
 * @returns {string} A standardized locale witg xx_XX format, available in the project. Defaults to en_US.
 */
export const getStandardizedLocale = locale => {
  if (!locale || typeof locale !== 'string') {
    return DEFAULT_LOCALE;
  }

  const availableLocales = Object.keys(AVAILABLE_LOCALES).map(
    key => AVAILABLE_LOCALES[key],
  );
  let formattedLocale = locale.replace('-', '_').toLowerCase();

  if (formattedLocale.match('_')) {
    formattedLocale = formattedLocale.split('_');
    formattedLocale[1] = formattedLocale[1].toUpperCase();
    formattedLocale = formattedLocale.join('_');
  } else {
    formattedLocale = `${formattedLocale}_${formattedLocale.toUpperCase()}`;
  }

  const isTranslated = availableLocales.includes(formattedLocale);

  if (isTranslated) {
    return formattedLocale;
  }

  const nearestLocale = availableLocales.filter(availableLocale => {
    const firstPart = formattedLocale.split('_')[0];
    return availableLocale.includes(firstPart);
  });

  if (nearestLocale.length) {
    return nearestLocale[0];
  }

  return DEFAULT_LOCALE;
};

const knownAnalyzers = [
  'ES',
  'DE',
  'FR',
  'PT',
  'TR',
];

export const analyzerFromLocale = locale => {
  const standardLocale = getStandardizedLocale(locale);
  const mainLocale = standardLocale.split('_')[0].toUpperCase(); // eslint-disable-line
  if (
    knownAnalyzers.indexOf(mainLocale) !== -1
  ) {
    return mainLocale;
  }
  return null;
};

/**
 * An Intl message Shape
 */
export const messageShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  description: PropTypes.string,
});
