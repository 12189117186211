/* eslint-disable global-require */
import 'custom-event-polyfill';
import 'core-js/fn/object/assign';
import 'core-js/fn/array/find';
import 'core-js/fn/array/from';
import 'core-js/fn/array/includes';
import 'core-js/es6/weak-map';
import 'core-js/es6/weak-set';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'whatwg-fetch';
import PromisePolyfill from 'promise-polyfill';

if (!window.Promise) {
  window.Promise = PromisePolyfill;
}

if (!window.Intl) {
  // load intl with fallback locale
  window.Intl = require('intl');
  require('intl/locale-data/jsonp/en');
}
