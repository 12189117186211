import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape } from 'react-intl';

import postHeight from 'shared/helpers/postHeight';
import LoadingWrapper from 'shared/components/LoadingWrapper';
import Theater from './Theater';
import Paging, { DIRECTIONS } from './Paging';
import css from './styles.css';

import tracking, { EventType } from '../../utils/tracking';
import { ELEMENT_KEYS } from '../../shared/constants';

const messages = defineMessages({
  noResults: {
    id: 'theaters-loading-no-results-message',
    defaultMessage: 'No results for your current search',
  },
});

class TheatersComponent extends PureComponent {
  componentDidUpdate() {
    postHeight();
  }

  render() {
    const { intl, getElementStyle } = this.context;
    const {
      appLocale,
      radius,
      loading,
      theatersShowtimes,
      movieLanguages,
      pageIndex,
      hasNextPage,
      cursors,
      onBadgeClick,
      dateRange,
      endCursor,
      onUpdatePageInfos,
      numberOfTheaters,
    } = this.props;

    return (
      <div
        className={css.theaters}
        style={getElementStyle(ELEMENT_KEYS.THEATERS)}
      >
        <LoadingWrapper loading={!radius || loading}>
          {radius &&
            !loading &&
            theatersShowtimes.length === 0 && (
              <div className={css.noResults}>
                <span className={css.text}>
                  {intl.formatMessage(messages.noResults)}
                </span>
              </div>
            )}
          {theatersShowtimes.map((theaterShowtimes, index) => (
            <div
              className={css.theater}
              key={`${theaterShowtimes.theater.id}-${index + 1}`}
            >
              <Theater
                appLocale={appLocale}
                theaterShowtimes={theaterShowtimes}
                index={index + 1 + numberOfTheaters * pageIndex}
                movieLanguages={movieLanguages}
                dateRange={dateRange}
                onBadgeClick={onBadgeClick}
              />
            </div>
          ))}
        </LoadingWrapper>
        <Paging
          hasPrevious={pageIndex > 0}
          hasNext={hasNextPage}
          onClick={direction => {
            tracking(EventType.PAGE_CHANGE, {
              direction,
            });
            if (direction === DIRECTIONS.NEXT) {
              const nextCursors = [...cursors];
              const nextPageIndex = pageIndex + 1;

              nextCursors[nextPageIndex] = endCursor;

              onUpdatePageInfos({
                pageIndex: nextPageIndex,
                cursors: nextCursors,
              });
            } else {
              onUpdatePageInfos({
                pageIndex: pageIndex - 1,
              });
            }
          }}
          disabled={loading}
        />
      </div>
    );
  }
}

TheatersComponent.contextTypes = {
  intl: intlShape.isRequired,
  getElementStyle: PropTypes.func.isRequired,
};

TheatersComponent.propTypes = {
  onUpdatePageInfos: PropTypes.func.isRequired,
  appLocale: PropTypes.string.isRequired,
  movieLanguages: PropTypes.arrayOf(PropTypes.string),
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  radius: PropTypes.number,
  numberOfTheaters: PropTypes.number,
  onBadgeClick: PropTypes.func.isRequired,
};

export default TheatersComponent;
