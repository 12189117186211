import { format as formatFns, addDays } from 'date-fns';
import memoizeIntlConstructor from 'intl-format-cache';

let getDateTimeFormat = null;

export const toDate = date => formatFns(date, 'YYYY-MM-DD');

export const getRange = (date = new Date()) => {
  const from = `${toDate(date)} 03:00:00`;
  const to = `${toDate(addDays(date, 1))} 02:59:59`;
  return { from, to };
};

export const normalizeLocale = locale => locale.replace('_', '-').toLowerCase();

export const DATE_FORMATS = {
  DAY: {
    // 17
    timeZone: 'UTC',
    day: 'numeric',
  },
  SHORT_MONTH: {
    // févr.
    timeZone: 'UTC',
    month: 'short',
  },
  WEEKDAY: {
    // ven.
    timeZone: 'UTC',
    weekday: 'short',
  },
  MONTH_YEAR: {
    timeZone: 'UTC',
    month: 'long',
    year: 'numeric',
  },
  HOUR_MINUTE: {
    // 17:30
    timeZone: 'UTC',
    hour: 'numeric',
    minute: 'numeric',
  },
};

/**
 * Formats an YMD date string using browser's locale representation
 *
 * If DD day is not defined, it is set to 1st day of month.
 *
 * Time is set to midnight UTC.
 *
 * @param  {String} ymdString   A string in the 'YYYY-MM-DD' or 'YYYY-MM' format
 * @param  {Object} format       One of DATE_FORMATS presets
 * @param  {Object} language     Locale language
 * @return {String}              The localized date for this very day of the year
 */
export function intlYMDString(ymdString, format, language) {
  if (!ymdString) return null;

  const year = parseInt(ymdString.substr(0, 4), 10);
  const month = parseInt(ymdString.substr(5, 2), 10) - 1; // 0 based value
  const day = parseInt(ymdString.substr(8, 2), 10) || 1;

  const date = new Date(Date.UTC(year, month, day));
  return intlDate(date, format, language);
}

/**
 * Formats a date according to browser's locale representation using Intl Native extension
 *
 * The locale translation (e.g. 25/12/2017 21:32 in French versus 12/25/2017 9:32PM in the US) depends
 * on the browser's language set in its preferences. It should eventually be a user setting instead.
 *
 * The date is localized in the UTC timezone, which is probably what the backend provides.
 * Use intlDateLocally() to localize the date to local (i.e. system) timezone.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
 *
 * @param  {Date}   date    A native Javascript Date object
 * @param  {Object} format  Format options. Use one of DATE_FORMATS presets
 * @param  {String} language  Locale language
 * @return {String}         The localized date, using the UTC timezone (or null if date is not truthy)
 */
export function intlDate(date, format, language = 'en_US') {
  if (!date) return null;
  if (!getDateTimeFormat) {
    getDateTimeFormat = memoizeIntlConstructor(Intl.DateTimeFormat);
  }
  return getDateTimeFormat(normalizeLocale(language), format).format(date);
}

export function convertUTCToLocal(date) {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
  );
}

export function convertLocalToUTC(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

export const normalizeDateTime = dateTime => {
  return `${dateTime.replace(' ', 'T')}Z`;
};
