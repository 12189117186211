exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3XlP- {\n  width: 50px;\n  height: 50px;\n}\n", ""]);

// exports
exports.locals = {
	"calendar": "_3XlP-"
};