exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n}\n\n._3phBE {\n  font-size: 16px;\n  font-family: Arial, Helvetica, sans-serif;\n  color: #333333;\n  background-color: #ffffff;\n  min-height: 850px;\n  position: relative;\n}\n\n._3pjvJ {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: rgba(0, 0, 0, .4);\n  z-index: 999;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  color: #ffffff;\n  font-size: 16px;\n  font-weight: 700;\n  z-index: 11;\n}\n", ""]);

// exports
exports.locals = {
	"main": "_3phBE",
	"aquisitionPosition": "_3pjvJ"
};