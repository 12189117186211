import { toDate } from 'utils/date';

const MIN_SCROLL = 2;

export const getSelectedItemIndex = (days, selected) => {
  if (selected > new Date(days[days.length - 1])) return 90;
  return days.map(toDate).indexOf(toDate(selected));
};

export const getScrollIndex = ({ dayWidth, scrollingNb, itemWidth }) => {
  if (itemWidth)
    return Math.max(Math.floor(itemWidth / dayWidth) - 1, MIN_SCROLL);
  return scrollingNb;
};

export const getShortISOString = date =>
  date ? date.toISOString().split('T')[0] : undefined;
