import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import loadScript from 'utils/loadScript';

class ScriptLoader extends PureComponent {
  state = {
    loaded: false,
  };

  componentWillMount() {
    const { url, options, canLoad } = this.props;
    if (canLoad) {
      this.scriptLoading = true;
      loadScript(url, options, this.onScriptLoaded);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { loaded } = this.state;
    const { url, options, canLoad } = nextProps;

    if (!this.scriptLoading && !loaded && this.props.canLoad !== canLoad) {
      this.scriptLoading = true;
      loadScript(url, options, this.onScriptLoaded);
    }
  }

  onScriptLoaded = () => {
    this.setState({
      loaded: true,
    });
  };

  render() {
    return this.props.children(this.state.loaded);
  }
}

ScriptLoader.propTypes = {
  canLoad: PropTypes.bool,
  url: PropTypes.string,
  options: PropTypes.object,
  children: PropTypes.func,
};

export default ScriptLoader;
