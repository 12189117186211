export const DEFAULT_NUMBER_OF_THEATERS = 5;
export const DEFAULT_TOKEN =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1NjUyNjQwMTAsInVzZXJuYW1lIjoiYW5vbnltb3VzIiwidXVpZCI6ImYxNzY0MGMyLTRlMzAtNDczOS1hYWJlLTI2ZTQ0NDZhN2RiMCIsImFwcGxpY2F0aW9uX25hbWUiOiJhY2Vfd2ViZWRpYSIsImV4cCI6MTg1MTI4NTYwMH0.sk0caAkyn89wzPqCuUdxjqvoLlS2FVjWbJCeS474ZKEIaawkrcEnKnuK7ONXHzdlLK4kcz6xyzFSgQWw2GWSSZUvNsDCILuq5wUH7nUQTVBdIgZItHzf_G-tDz8mURLTb6Bi7_dP3KXSm0-CW_zi9vPpwAzv70FAS1U_1Po2hOpLulrHYUq_zmsvxgqExaGZe0HectJSI0tTphG11iLfvuUUaCcomi8et8woyM_JoY_CLyz3mlxHqsQyxE5C2__RKa7-GzqTyajleKkKWzkDrG-yK56ObT_UyCufgApjr0oHCz1awxSxvaUExXmJ7BOuZuyGDEGntgasyqEfSOLokS7AgHvrS3XsC-VGTO6U-lRr7cFmNcpt9oZYMf0iwL8t7XtmIT9-Eqmqnv87ez5NnEkioB34AImmZGUwOYI991InTKDvWaklJX5El1GpdXpeOlQHWT9xhn6RtKvCHhUN5ECNUB8fYH1qFosS_UWcgE5Zgs4AFyfNn50mhb0AMUxl85vxcEy1y3nAJhb9p88KZudnT1TwlwxLjwNOio1wNaTt-evpruD6kg_r9wfNXXYRpaoKxKnvcyKRAOn4-M42hIUWFbjZay5lhahXTWk2LL3kbl2yLtNBc69WOi6y1-qVWf3Vwwdni0m_Lh0qY1dAzRWZkdZBJquL3Ext46kJu3k';
export const DEFAULT_GOOGLE_API_KEY = 'AIzaSyDYMh3eS7hGBAckiqhQVILMNmy1hlDgws4';
export const DEFAULT_API_ENDPOINT = 'https://graph.allocine.fr/v1/ace_webedia/';

export const DEFAULT_STYLES = [
  {
    element: 'map.marker',
    style: {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
  {
    element: 'search.location',
    style: {
      color: '#000',
    },
  },
  {
    element: 'search.button',
    style: {
      backgroundColor: '#000',
    },
  },
  {
    element: 'search.button:hover',
    style: {
      backgroundColor: '#333',
    },
  },
  {
    element: 'dates.controls',
    style: {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
  {
    element: 'dates.controls:hover',
    style: {
      backgroundColor: '#777',
      color: '#fff',
    },
  },
  {
    element: 'dates.item:selected',
    style: {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
  {
    element: 'theaters.loader',
    style: {
      color: '#000',
    },
  },
  {
    element: 'theaters.item.badge',
    style: {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
  {
    element: 'schedules.button',
    style: {
      borderColor: '#000',
    },
  },
  {
    element: 'schedules.button:hover',
    style: {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
  {
    element: 'theaters.pagination.button',
    style: {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
];
