import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import ApolloQuery from 'utils/apollo/ApolloQuery';

const query = gql`
  query TheatersList(
    $after: String
    $before: String
    $countries: [CountryCode]
    $first: Int
    $from: DateTime
    $location: CoordinateType
    $movieId: String!
    $order: [ShowtimeSorting]
    $radius: Float
    $search: String
    $to: DateTime
  ) {
    theaterShowtimeList(
      after: $after
      before: $before
      country: $countries
      first: $first
      from: $from
      location: $location
      movie: $movieId
      order: $order
      radius: $radius
      search: $search
      to: $to
    ) {
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          theater {
            id
            name
            timezone
            location {
              address
              zip
              city
              country
            }
            timezone
            theaterCircuits {
              name
            }
          }
          showtimes {
            id
            startsAt
            format
            version
            data {
              desktop: ticketing(type: DESKTOP, provider: "default") {
                urls
              }
            }
          }
        }
      }
    }
  }
`;

// Parser exemple
const parser = ({ theaterShowtimeList }) => {
  if (
    theaterShowtimeList &&
    theaterShowtimeList.edges &&
    theaterShowtimeList.edges.length
  ) {
    return theaterShowtimeList.edges.map(({ node }) => node || {});
  }
  return [];
};

const GetTheaters = ({
  children,
  movieId,
  countries,
  dateRange,
  location,
  radius,
  first,
  before,
  search,
  after,
  skip,
}) => (
  <ApolloQuery
    query={query}
    parser={parser}
    config={{
      loadMorePathGetter: ({ theaterShowtimeList }) => theaterShowtimeList,
    }}
    skip={skip}
    variables={{
      before,
      after,
      countries,
      first,
      search,
      order: ['CLOSEST', 'ALPHABETICAL'],
      from: dateRange.from,
      location: location ? { lat: location.lat, lon: location.lng } : undefined,
      movieId,
      radius,
      skip,
      to: dateRange.to,
    }}
  >
    {children}
  </ApolloQuery>
);

GetTheaters.propTypes = {
  before: PropTypes.string,
  after: PropTypes.string,
  search: PropTypes.string,
  children: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateRange: PropTypes.shape({ from: PropTypes.string, to: PropTypes.string }),
  first: PropTypes.number,
  location: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
  movieId: PropTypes.string,
  radius: PropTypes.number,
  skip: PropTypes.bool,
};

export default GetTheaters;
