import marker from 'shared/assets/marker.png';

// eslint-disable-next-line import/prefer-default-export
export const GOOGLE_MAP_MARKER = {
  src: marker,
  color: '#ffffff',
  width: 31,
  height: 37,
  font: '11px Arial',
  labelOffsetY: 19,
  shape: [
    15,
    0,
    4,
    4,
    -1,
    15,
    2,
    24,
    8,
    29,
    12,
    30,
    16,
    35,
    19,
    30,
    25,
    27,
    29,
    24,
    28,
    7,
    24,
    1,
    18,
    0,
  ],
  svgShape:
    'M31,15.72a15.5,15.5,0,1,0-19.46,15l4,6.29L19,30.83A15.5,15.5,0,0,0,31,15.72Z',
  backgroundColor: '#072338',
};
