exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Be0h {\n  width: 10px;\n  height: 20px;\n}\n\n._2Be0h.fIAQj {\n  height: 8px;\n}\n\n._2Be0h._3TKCO polyline {\n  stroke: currentColor;\n}\n\n._2Be0h.fIAQj polyline {\n  stroke: currentColor;\n  stroke-width: 12;\n}\n", ""]);

// exports
exports.locals = {
	"arrow": "_2Be0h",
	"white": "fIAQj",
	"black": "_3TKCO"
};