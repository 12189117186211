exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n}\n\n._3er-8 {\n  height: 27px;\n  width: 27px;\n  fill: currentColor\n}\n\n._3er-8:hover {\n  opacity: 0.75;\n}\n", ""]);

// exports
exports.locals = {
	"icon": "_3er-8"
};