import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SEARCH_RESULTS_TYPES } from './constants';

import GetByCity from './queries/GetByCity';
import GetBySearch from './queries/GetBySearch';

import SearchBar from './SearchBar';

class Search extends PureComponent {
  parseResults = (cities, theaters) =>
    [
      ...(cities || []).map(city => ({
        ...city,
        type: SEARCH_RESULTS_TYPES.CITY,
      })),
      ...(theaters || []).map(theater => ({
        ...theater,
        type: SEARCH_RESULTS_TYPES.THEATER,
      })),
    ].map((x, index) => ({ ...x, index }));

  render() {
    const {
      countries,
      pattern,
      debouncedPattern,
      setPattern,
      onSelect,
      openGeolocPrompt,
      movieId,
      startDate,
      radius,
      analyzer,
    } = this.props;

    return (
      <GetByCity
        countries={countries}
        search={debouncedPattern}
        radius={radius}
        analyzer={analyzer}
      >
        {(cities, { loading: cityLoading }) => (
          <GetBySearch
            countries={countries}
            search={debouncedPattern}
            movieId={movieId}
            startDate={startDate}
            radius={radius}
            analyzer={analyzer}
          >
            {(theaters, { loading: searchLoading }) => (
              <SearchBar
                onChange={setPattern}
                onSelect={onSelect}
                pattern={pattern}
                openGeolocPrompt={openGeolocPrompt}
                loading={cityLoading || searchLoading}
                searchResults={
                  pattern ? this.parseResults(cities, theaters) : undefined
                }
              />
            )}
          </GetBySearch>
        )}
      </GetByCity>
    );
  }
}

Search.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.string),
  pattern: PropTypes.string,
  analyzer: PropTypes.string,
  debouncedPattern: PropTypes.string,
  setPattern: PropTypes.func,
  startDate: PropTypes.instanceOf(Date).isRequired,
  radius: PropTypes.number.isRequired,
};

export default Search;
