import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Footer from './Footer';
import Calendar from './Calendar';

import { getGrid, nextMonth, prevMonth } from './helpers';

import css from './style.css';

class DatePicker extends Component {
  onPrevMonth = () => {
    const { date, onDate } = this.props;
    if (onDate) onDate(prevMonth(date));
  };

  onNextMonth = () => {
    const { date, onDate } = this.props;
    if (onDate) onDate(nextMonth(date));
  };

  onClickBackground = () => {
    this.props.onClose();
  };

  setCursor = date => {
    const { onDate, onClose } = this.props;
    if (onDate) onDate(date);
    if (onClose) onClose();
  };

  preventDefault = event => {
    event.preventDefault(); // Let's stop this event.
    event.stopPropagation(); // Really this time.
  };

  render() {
    const { appLocale, onClose, date } = this.props;

    return (
      <div className={css.container} onClick={this.onClickBackground}>
        <div className={css.content} onClick={this.preventDefault}>
          <Header
            appLocale={appLocale}
            cursor={date}
            onPrev={this.onPrevMonth}
            onNext={this.onNextMonth}
            month={null}
          />
          <Calendar
            appLocale={appLocale}
            days={getGrid(date)}
            cursor={date}
            onClick={this.setCursor}
          />
          <Footer onToday={this.setCursor} onClose={onClose} />
        </div>
      </div>
    );
  }
}

DatePicker.propTypes = {
  appLocale: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDate: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
};

export default DatePicker;
