import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import values from 'utils/values';
import { THEMES } from '../constants';
import css from './style.css';

const ArrowLeft = ({ className, theme }) => (
  <svg
    className={classnames(css.arrow, className, css[theme])}
    viewBox="0 0 50 80"
  >
    <polyline
      fill="none"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      points="
	45.63,75.8 0.375,38.087 45.63,0.375 "
    />
  </svg>
);

ArrowLeft.defaultProps = {
  theme: THEMES.BLACK,
};

ArrowLeft.propTypes = {
  theme: PropTypes.oneOf(values(THEMES)),
};

export default ArrowLeft;
