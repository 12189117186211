import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import cloneDeep from 'lodash/cloneDeep';

const upgradeDataCompatibility = data => ({ viewer: {}, ...(data || {}) });

const customFetchMore = (fetchMore, data, config) => (nb = 30) => {
  const { pageInfo } = config.loadMorePathGetter(data) || {};

  if (nb <= 0 || !pageInfo) return false;

  return fetchMore({
    variables: {
      after: pageInfo.endCursor,
      first: nb,
    },
    updateQuery: (previousResult, { fetchMoreResult: result }) => {
      const {
        pageInfo: nextPageInfo,
        edges: nextEdges,
      } = config.loadMorePathGetter(result);
      const nextResult = cloneDeep(previousResult);
      // eslint-disable-next-line
      config.loadMorePathGetter(nextResult).pageInfo = nextPageInfo;
      // eslint-disable-next-line
      config.loadMorePathGetter(nextResult).edges = [
        ...config.loadMorePathGetter(previousResult).edges,
        ...nextEdges,
      ];
      return nextResult;
    },
  });
};

const getPageInfoParam = (param, config, data) => {
  const { pageInfo } = config.loadMorePathGetter(data || undefined) || {};
  return pageInfo ? pageInfo[param] : false;
};

const ApolloQuery = ({
  children,
  variables,
  config,
  skip,
  query,
  parser,
  notifyOnNetworkStatusChange,
  fetchPolicy,
  shouldBatch,
}) => {
  const isLoadMore = !!(config && config.loadMorePathGetter);

  return (
    <Query
      query={{ ...query, shouldBatch }}
      variables={variables}
      skip={skip}
      notifyOnNetworkStatusChange={notifyOnNetworkStatusChange || isLoadMore}
      fetchPolicy={fetchPolicy}
      shouldBatch
    >
      {({ loading, error, data, fetchMore }) => {
        const dataUpgraded = upgradeDataCompatibility(data);
        const dataParsed = parser ? parser(dataUpgraded) : dataUpgraded;

        return children(dataParsed || undefined, {
          loading: loading && !skip,
          error,
          fetchMore,
          hasNextPage:
            isLoadMore && !loading
              ? getPageInfoParam('hasNextPage', config, data)
              : undefined,
          endCursor:
            isLoadMore && !loading
              ? getPageInfoParam('endCursor', config, data)
              : undefined,
          loadMore: isLoadMore
            ? customFetchMore(fetchMore, data, config)
            : undefined,
        });
      }}
    </Query>
  );
};

ApolloQuery.defaultProps = {
  config: undefined,
  fetchPolicy: 'cache-first',
  notifyOnNetworkStatusChange: true,
  shouldBatch: false,
  skip: false,
  variables: undefined,
};

ApolloQuery.propTypes = {
  children: PropTypes.func.isRequired,
  config: PropTypes.shape({
    loadMorePathGetter: PropTypes.func,
  }),
  fetchPolicy: PropTypes.string,
  notifyOnNetworkStatusChange: PropTypes.bool,
  parser: PropTypes.func.isRequired,
  shouldBatch: PropTypes.bool,
  skip: PropTypes.bool,
  variables: PropTypes.object,
};

export default ApolloQuery;
