import PropTypes from 'prop-types';

/*
  ApolloQueryPromise(client<context>, query<string>, variables<object>, {
    skip: ownProps => ownProps.skip,
  })
    .then((data, res) => console.log('data', data, res))
    .catch(error => { ... });
*/
const ApolloQueryPromise = (client, query, variables = {}, config = {}) =>
  new Promise((resolve, reject) => {
    const overriddenConfig = {
      ...config,
      options: {
        ...(config.options || {}),
        // override options
        notifyOnNetworkStatusChange: true,
      },
    };

    client
      .query({
        ...overriddenConfig,
        query: { ...query, shouldBatch: config.shouldBatch },
        variables,
      })
      .then(res => resolve(res.data, res))
      .catch(reject);
  });

// context type:
export const CLIENT_CONTEXT_TYPE = PropTypes.object.isRequired;

export default ApolloQueryPromise;
