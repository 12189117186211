const setAttributes = (script, attrs) =>
  Object.keys(attrs).forEach(key => {
    script.setAttribute(key, attrs[key]);
  });

const load = (src, options = {}, callback = () => {}) => {
  const script = document.createElement('script');

  script.type = options.type || 'text/javascript';
  script.charset = options.charset || 'utf8';
  script.async = options.async || true;
  script.src = src;

  if (options.attrs) {
    setAttributes(script, options.attrs);
  }

  if (options.text) {
    // Here we force text value to be a string
    script.text = `${options.text}`;
  }

  script.onload = () => callback(null, script);

  script.onerror = () =>
    callback(new Error(`Failed to load ${script.src}`), script);

  document.head.appendChild(script);
};

export default load;
