export default (element, additionalHeight = 0) => {
  if (parent && window && window.addEventListener) {
    const height = Math.max(
      document.body.getBoundingClientRect().height + additionalHeight,
      element ? element.getBoundingClientRect().height : 0,
    );
    const payload = { source: 'ace-widget', property: 'height', value: height };
    parent.postMessage(payload, '*');
  }
};
