import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cancelable from 'makecancelable';

import { GOOGLE_MAP_MARKER } from 'config/map/marker';
import {
  generateMapMarkerIcon,
  createMarker,
  addMarker,
  removeMarker,
} from 'utils/map';
import { ELEMENT_KEYS } from 'shared/constants';

class MapMarker extends PureComponent {
  state = {
    marker: undefined,
  };

  componentWillMount() {
    const { mapInstance, coords, index } = this.props;
    const { getElementStyle } = this.context;

    if (!this.state.marker) {
      this.cancelMarkerCreation = cancelable(
        generateMapMarkerIcon(index, {
          ...GOOGLE_MAP_MARKER,
          ...getElementStyle(ELEMENT_KEYS.MAP_MARKER),
        }),
        (icon, shape) => {
          const marker = createMarker(coords, icon, shape);

          if (!marker.getMap()) {
            addMarker(marker, mapInstance);
          }

          this.setState({ marker });
        },
      );
    }
  }

  componentWillReceiveProps({ index }) {
    const { getElementStyle } = this.context;

    if (index !== this.props.index) {
      if (this.cancelMarkerUpdate) this.cancelMarkerUpdate();

      this.cancelMarkerUpdate = cancelable(
        generateMapMarkerIcon(index, {
          ...GOOGLE_MAP_MARKER,
          ...getElementStyle(ELEMENT_KEYS.MAP_MARKER),
        }),
        (icon, shape) => {
          if (this.state.marker) {
            this.state.marker.setIcon(icon);
            this.state.marker.setShape(shape);
          }
        },
      );
    }
  }

  componentWillUnmount() {
    if (this.cancelMarkerCreation) this.cancelMarkerCreation();
    if (this.cancelMarkerUpdate) this.cancelMarkerUpdate();

    if (this.state.marker) {
      removeMarker(this.state.marker);
    }
  }

  render() {
    return null;
  }
}

MapMarker.contextTypes = {
  getElementStyle: PropTypes.func.isRequired,
};

MapMarker.propTypes = {
  index: PropTypes.number,
  mapInstance: PropTypes.object,
  coords: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default MapMarker;
