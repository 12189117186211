import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { intlYMDString, DATE_FORMATS, toDate } from 'utils/date';

import ArrowLeft from 'components/icons/ArrowLeft';
import ArrowRight from 'components/icons/ArrowRight';

import css from './style.css';

const Header = ({ appLocale, cursor, onPrev, onNext }) => (
  <div className={css.container}>
    <div onClick={onPrev} className={classnames(css.button, css.center)}>
      <ArrowLeft />
    </div>
    <div className={classnames(css.title, css.center)}>
      {intlYMDString(toDate(cursor), DATE_FORMATS.MONTH_YEAR, appLocale)}
    </div>
    <div onClick={onNext} className={classnames(css.button, css.center)}>
      <ArrowRight />
    </div>
  </div>
);

Header.propTypes = {
  appLocale: PropTypes.string.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  cursor: PropTypes.object,
};

export default Header;
