exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n}\n\n._3phG6 {\n  display: block;\n  text-align: center;\n  font-size: 16px;\n}\n\n._3rc7k,\n._2bNG3 {\n  cursor: pointer;\n  outline: none;\n  margin: 32px 16px;\n  border: 0;\n  background-color: #5fa943;\n  color: #ffffff;\n  padding: 12px 16px;\n  text-transform: uppercase\n}\n\n._3rc7k:hover, ._2bNG3:hover {\n  background-color: #0e334e;\n}\n\n._3rc7k ._1P8u3 {\n  margin-right: 4px;\n}\n\n._2bNG3 ._1P8u3 {\n  margin-left: 4px;\n}\n", ""]);

// exports
exports.locals = {
	"paging": "_3phG6",
	"prev": "_3rc7k",
	"next": "_2bNG3",
	"icon": "_1P8u3"
};