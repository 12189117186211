exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1VhRR {\n  position: relative;\n  height: 380px;\n  min-height: 380px;\n  width: 100%;\n  border-bottom: 1px solid rgba(0, 0, 0, .25);\n}\n\n._1uFq6 {\n  height: 100%;\n  /*\n  HERE IS SOME CODE TO REMOVE MAP MENTIONS\n  & a[href^='http://maps.google.com/maps'] {\n    display: none !important;\n  }\n  & a[href^='https://maps.google.com/maps'] {\n    display: none !important;\n  }\n  & :global(.gmnoprint) a,\n  & :global(.gm-style-cc) {\n    display: none !important;\n  } */\n}\n", ""]);

// exports
exports.locals = {
	"container": "_1VhRR",
	"map": "_1uFq6"
};