import gql from 'graphql-tag';

export const query = gql`
  query MapResults(
    $after: String
    $countries: [CountryCode]
    $endDate: DateTime
    $first: Int
    $location: CoordinateType
    $movieId: String!
    $order: [ShowtimeSorting]
    $radius: Float
    $search: String
    $startDate: DateTime
  ) {
    theaterShowtimeList(
      after: $after
      country: $countries
      first: $first
      from: $startDate
      location: $location
      movie: $movieId
      radius: $radius
      to: $endDate
      search: $search
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          theater {
            id
            name
            coordinates {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;

export const parser = ({ theaterShowtimeList }) => {
  if (
    theaterShowtimeList &&
    theaterShowtimeList.edges &&
    theaterShowtimeList.edges.length
  ) {
    return theaterShowtimeList.edges.map(({ node }) => node.theater || {});
  }
  return undefined;
};
