/* eslint-disable no-unused-vars, react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { defineMessages, intlShape } from 'react-intl';
import isWithinRange from 'date-fns/is_within_range';

import { ELEMENT_KEYS } from 'shared/constants';
import tracking, { EventType } from '../../../utils/tracking';

import Schedule from './Schedule';
import css from './styles.css';
import messages from './intl';
import { GROUP_FROM_FORMATS, FORMAT_KEYS, FORMAT_PRIORITY } from './constants';
import { formatShowtimesHour, isExpired } from './helpers';

const Theater = (
  {
    appLocale,
    theaterShowtimes,
    index,
    movieLanguages,
    dateRange,
    onBadgeClick,
  },
  { intl, getElementStyle },
) => {
  const showtimesByFormat = [];

  theaterShowtimes.showtimes.forEach(showtime => {
    if (!showtime) return;
    const format = GROUP_FROM_FORMATS[showtime.format] || FORMAT_KEYS.F_2D;
    if (!showtimesByFormat[format]) {
      showtimesByFormat[format] = [];
    }

    if (isWithinRange(showtime.startsAt, dateRange.from, dateRange.to)) {
      showtimesByFormat[format].push(showtime);
    }
  });

  const { address, city, zip } = theaterShowtimes.theater.location;
  const theaderDescription = `${address || ''} ${zip || ''} ${city || ''}`;

  return (
    <div
      className={css.theater}
      style={getElementStyle(ELEMENT_KEYS.THEATERS_ITEM)}
    >
      <div className={css.header}>
        <div className={css.formatBar}>
          <div
            className={css.badge}
            style={getElementStyle(ELEMENT_KEYS.THEATERS_ITEM_BADGE)}
            onClick={() => onBadgeClick(theaterShowtimes.theater.id)}
          >
            {index}
          </div>
        </div>
        <div className={css.scheduleBar}>
          <div className={css.title}>{theaterShowtimes.theater.name}</div>
          <div className={css.desc}>{theaderDescription}</div>
        </div>
      </div>
      {Object.keys(showtimesByFormat)
        .sort((a, b) => (FORMAT_PRIORITY[a] > FORMAT_PRIORITY[b] ? 1 : -1))
        .map(key => {
          const format = FORMAT_KEYS[key];
          return (
            <div key={format} className={css.group}>
              <div
                className={classnames(css.formatBar, css.formatText)}
                style={getElementStyle(ELEMENT_KEYS.SCHEDULES_FORMAT)}
              >
                {intl.formatMessage(messages[key])}
              </div>
              <div className={css.scheduleBar}>
                <div className={css.wrap}>
                  {showtimesByFormat[key].map((showtime, i) => (
                    <Schedule
                      key={i}
                      disabled={isExpired(
                        showtime.startsAt,
                        theaterShowtimes.theater.timezone,
                        appLocale,
                      )}
                      hour={formatShowtimesHour(showtime.startsAt, appLocale)}
                      version={showtime.version}
                      movieLanguages={movieLanguages}
                      ticketingUrl={
                        showtime.data &&
                          showtime.data.desktop.length &&
                          showtime.data.desktop[0].urls.length
                          ? showtime.data.desktop[0].urls[0]
                          : undefined
                      }
                      onClick={() => {
                        tracking(
                          EventType.LINK_OUT,
                          {
                            theater: theaterShowtimes.theater.name,
                            circuit: theaterShowtimes.theater.theaterCircuits ? theaterShowtimes.theater.theaterCircuits.name : null,
                            showtime: {
                              startsAt: showtime.startsAt,
                              version: showtime.version,
                              format: key
                            }
                          }
                        )
                      }}
                    />
                  ))}
                  <div className={css.escape} />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

Theater.propTypes = {
  appLocale: PropTypes.string.isRequired,
  theaterShowtimes: PropTypes.shape({
    theater: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      location: PropTypes.shape({
        address: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        zip: PropTypes.string,
      }),
    }),
    showtimes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        format: PropTypes.string,
        startsAt: PropTypes.string,
        data: PropTypes.shape({
          ticketingUrl: PropTypes.arrayOf(PropTypes.string),
        }),
      }),
    ),
  }),
  movieLanguages: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  onBadgeClick: PropTypes.func.isRequired,
};

Theater.contextTypes = {
  getElementStyle: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default Theater;
