import React from 'react';
import PropTypes from 'prop-types';
import { intlYMDString, DATE_FORMATS, toDate } from 'utils/date';

import Day from './Day';

import css from './style.css';

const Calendar = ({ appLocale, days, cursor, onClick }) => (
  <div className={css.container}>
    {days.slice(0, 7).map(date => (
      <div key={`week-${toDate(date)}`} className={css.weekDay}>
        {intlYMDString(toDate(date), DATE_FORMATS.WEEKDAY, appLocale)}
      </div>
    ))}
    {days.map(date => (
      <Day
        appLocale={appLocale}
        key={toDate(date)}
        onClick={onClick}
        cursor={cursor}
        date={date}
      />
    ))}
  </div>
);

Calendar.propTypes = {
  appLocale: PropTypes.string.isRequired,
  days: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  cursor: PropTypes.object.isRequired,
};

export default Calendar;
