import {
  startOfMonth,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  addMonths,
  eachDay,
  subMonths,
  addDays,
  isSameMonth,
} from 'date-fns';

export const getGrid = today => {
  const monthStart = startOfMonth(today);
  const weekStart = startOfWeek(monthStart);

  const monthEnd = endOfMonth(today);
  const weekEnd = endOfWeek(monthEnd);

  const days = eachDay(weekStart, weekEnd);

  if (days.length < 42) {
    const startAddingDate = addDays(weekEnd, 1);
    return [
      ...days,
      ...eachDay(startAddingDate, addDays(startAddingDate, 41 - days.length)),
    ];
  }

  return days;
};

export const nextMonth = date => addMonths(date, 1);

export const prevMonth = date => subMonths(date, 1);

export const inMonth = (a, b) => isSameMonth(a, b);
