import isAfter from 'date-fns/is_after';

import {
  intlDate,
  DATE_FORMATS,
  normalizeDateTime,
  normalizeLocale,
} from 'utils/date';

export const formatShowtimesHour = (hour, appLocale) => {
  return intlDate(
    new Date(normalizeDateTime(hour)),
    DATE_FORMATS.HOUR_MINUTE,
    appLocale,
  );
};

function addLeadingZeros(number, targetLength) {
  const sign = number < 0 ? '-' : '';
  let output = Math.abs(number).toString();
  while (output.length < targetLength) {
    output = '0' + output; // eslint-disable-line
  }
  return sign + output;
}

export const normalizeTimeZone = timezone => {
  const regex = /[a-zA-Z]+(\+|-)(\d)+/
  const parts = regex.exec(timezone);
  if (!parts) {
    return '';
  }
  return `${parts[1]}${addLeadingZeros(parts[2], 2)}:00`;
};


export const getTimezonedDateString = (
  timeString,
  originalTime,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  appLocale,
) => {
  const date = new Date(timeString);
  const normalizedLocale = normalizeLocale(appLocale);

  const formatOptions = {
    timeZone,
    hour12: false,
    second: 'numeric',
    timeZoneName: 'short',
  };

  const formattedTimezone = date
    .toLocaleString(normalizedLocale, {
      ...formatOptions,
    })
    .split(' ')[1];

  return `${originalTime}${normalizeTimeZone(formattedTimezone)}`;
};


export const isExpired = (hour, timezone, appLocale) => {
  const now = new Date();
  const sanitizedHour = hour.replace('T', ' ');
  const adjustedTime = new Date(
    getTimezonedDateString(sanitizedHour, hour, timezone, appLocale),
  );
  return isAfter(now, adjustedTime);
};
