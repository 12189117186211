import ace from '../src/index';
import { areParamsValid, getParams } from './helpers';
import {
  DEFAULT_GOOGLE_API_KEY,
  DEFAULT_NUMBER_OF_THEATERS,
  DEFAULT_STYLES,
  DEFAULT_TOKEN,
} from './constants';

const startWidget = params => {
  if (!areParamsValid(params)) {
    return;
  }

  ace(document.getElementById('app'), {
    googleApiKey: params.googleApiKey || DEFAULT_GOOGLE_API_KEY,
    token: params.token || DEFAULT_TOKEN,
    movieId: btoa(`Movie:${params.movieId}`),
    countries: [params.countries],
    locale: params.locale,
    date: params.startDate,
    numberOfTheaters: params.numberOfTheaters || DEFAULT_NUMBER_OF_THEATERS,
    styles: params.styles || DEFAULT_STYLES,
  });

  const widget = window.ace;
  const { LINK_OUT } = widget.EventType;

  if (global.ga) {
    widget.addEventListener(LINK_OUT, event => {
      global.ga('send', {
        hitType: 'event',
        eventCategory: 'showtime',
        eventAction: 'click',
        eventLabel: `${event.detail.circuit} / ${event.detail.theater} / ${
          event.detail.showtime.startsAt
        }`,
      });
    });
  }
};

document.addEventListener('DOMContentLoaded', () => {
  const params = getParams();

  if (process.env.DEMO) {
    /**
     * Hydrate the Form or update url params from the Form in Demo mode
     */
    const urlParams = new URLSearchParams(document.location.search);

    const movieIdInput = document.getElementById('movieId');

    if (movieIdInput) {
      if (params.movieId) {
        movieIdInput.value = params.movieId;
      } else {
        urlParams.append('movieId', encodeURIComponent(movieIdInput.value));
      }
    }

    const countriesInput = document.getElementById('countries');

    if (countriesInput) {
      if (params.countries) {
        countriesInput.value = params.countries;
      } else {
        urlParams.append('countries', encodeURIComponent(countriesInput.value));
      }
    }

    const localeInput = document.getElementById('locale');

    if (localeInput) {
      if (params.locale) {
        localeInput.value = decodeURIComponent(params.locale);
      } else {
        urlParams.append('locale', encodeURIComponent(localeInput.value));
      }
    }

    const startDateInput = document.getElementById('startDate');

    if (startDateInput) {
      if (params.startDate) {
        startDateInput.value = decodeURIComponent(params.startDate);
      } else {
        urlParams.append('startDate', encodeURIComponent(startDateInput.value));
      }
    }

    window.history.pushState('', '', `?${urlParams.toString()}`);
  }

  startWidget(params);
});
